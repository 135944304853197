:root {
  --HintSHadow : 0px 0px 10px 4px rgba(230, 218, 218,1);
  --Color-Main: #7367F0;
  --Color-Light: #5349B4;
  --Color-Border: #4E44B4;
  --Main-BG: #FFFFFF;
  --Card-BG: #FFFFFF;
  --Green:rgb(0, 185, 152);
  --Yellow:#FF9500;
  --Fiat:#FF80A6;
  --Crypto:#5653FC;
  --Text:#2B2A35;
  --Header:#2A2746;
  --Border:#CCCBCB;
  --Hover:#897DFF;
  --Click:#5A4ED4;
  --White:#FFF;
  --Error:rgb(213, 26, 82);
  --light-border-10:rgba(0, 0, 0,0.2);
  --Disabled:#D4D2D5;
  --Disabled-Text:#888888;
  --Link:#5E56A8;
  --Link-Hover:#7468F0;
  --Link-Click:#D0CCFF;
  --input-bg-10:rgba(255, 255, 255,0.1);
  --Placeholder:#888888;
  --Page-BG-Main-Color:#EAEAEA;
  --Page-BG-Color:#FFFFFF;
  --Page-BG-Color-2:#FFFFFF;
  --Separator:#E5E0EB;
  --Darck-shadow:0px;
  --Spacer_light: #E5E0EB;
  --Informing-Approval: #00B998;
}  

.DarkTopic {
  --HintSHadow : 0px 0px 10px 4px rgba(23,23,23,1);
  --Color-Main: #7367F0;
  --Color-Light: #9F96F9;
  --Color-Border: #4E44B4;
  --Main-BG: #161616;
  --Card-BG: #1F1F1F;
  --Green:rgb(0, 185, 152);
  --Yellow:#FF9500;
  --Fiat:#FF80A6;
  --Crypto:#5653FC;
  --Text:#CFCDE4;
  --Header:#FFFFFF;
  --Border:#303030;
  --Hover:#897DFF;
  --Click:#5A4ED4;
  --White:#FFF;
  --Error:#D51A52;
  --light-border-10:rgba(217, 209, 224,0.1);
  --Disabled:#D4D2D5;
  --Disabled-Text:#888888;
  --Separator:#4D4C4E;
  --Link:#9F96F9;
  --Link-Hover:#7468F0;
  --Link-Click:#D0CCFF;
  --input-bg-10:rgba(255, 255, 255,0.1);
  --Placeholder:#888888;
  --Page-BG-Main-Color:#161616;
  --Page-BG-Color:#472B17;
  --Page-BG-Color-2:#140841;
  --Darck-shadow:50px;
  --Grayscaler-Border:#ABA7AF;
  --Spacer_light: #E5E0EB;
  --Informing-Approval: #00B998;
}
a{
  text-decoration: none;
  color: var(--Link);
}
a svg{
  fill: var(--Link);
}
a:hover{
  color: var(--Link-Hover);
  cursor: pointer;
}
a:hover svg{
  fill: var(--Link-Hover);
}
a:active{
  color: var(--Link-Click);
}
a:active svg{
  fill: var(--Link-Click);
}

.App{
  overflow-x: hidden;
}
h4{
  position: relative;
  color: var(--Header, #FFF);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%; /* 16px */
}


.App.DarkTopic{
  /* background:linear-gradient(206deg, #161616 6.47%, #313131 95.34%); */
  background-image: url('../src/assets/images/bg.svg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
/* background:  */

.headerLogo .iconDiv:not(.headerTheme .iconDiv) {
  height: 60px;
}

.date-picker {
  position: relative;
  font-family: Arial, sans-serif;
  color: var(--Header);
}
.dateIcon{
  position: absolute;
  right: 5px;
  top: 8px;
  cursor: pointer;
}
.date-picker .dateIcon svg{
  fill:var(--Placeholder)
}
.userEditLeft .dateIcon{
  top:12px;
}
.calendar {
  position: absolute;
  top: 40px;
  left: 0;
  background: var(--Card-BG);
  border: 1px solid #444;
  padding: 10px;
  border-radius: 5px;
  z-index: 1;
}

.calendar.alignRight {
  position: absolute;
  top: 40px;
  left: unset;
  right: 0;
  background: var(--Card-BG);
  border: 1px solid #444;
  padding: 10px;
  border-radius: 5px;
  z-index: 1;
}

.calendar .header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}
.calendar .header span{
  width: 140px;
  text-align: center;
}
.calendar .header.prFlexRow{
  gap:unset;
}

.dFlex{
  display: flex;
}
.jCC{
  justify-content: center;
}
.calendar .header button {
  background: none;
  border: none;
  color: var(--Text);
  cursor: pointer;
}
.calendar .header button svg{
  fill: var(--Link);
}
.calendar .header button:hover svg{
  fill: var(--Link-Hover);
}
.calendar .header button:active svg{
  fill: var(--Link-Click);
}
.days {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
}

.day-name,
.day,
.empty-day {
  padding: 10px;
  text-align: center;
}

.day {
  cursor: pointer;
  color: var(--Text);
}
.day.active{
  color: var(--Link);
}

.day:hover {
  background: var(--Link-Hover);
  border-radius: 50%;
}

.prFlexRow{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.prButton{
  font-family: 'Montserrat';
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  font-family: 'Montserrat';
  line-height: 148%;
  /* text-transform: uppercase; */
  padding: 8px 28px;
  border-radius: 4px;
  cursor: pointer;
}
.prButton:disabled{
  background-color: var(--Disabled);
  cursor:default;
}
.prButton:disabled:hover{
  background-color: var(--Disabled);
}
textarea{
  border-radius: 2px;
  border: 1px solid var(--light-border-10);
  background: var(--input-bg-10);
  padding: 12px;
  color: var(--Header);
  width: 100%;
  margin-top: 20px;
  resize: none;
}
.bigSidebar .prInput .simpleText{
  padding: 0;
}
.prflexColumn{
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: flex-start;
}
.multiselect {
  position: relative;
  width: 100%;
  box-sizing: border-box;
  border-radius: 2px;
  border: 1px solid transparent;
}
.multiselect.focused {
  color: var(--Text);
  border: 1px solid var(--Color-Main);
  background: var(--input-bg-10);
  border: 1px solid var(--Color-Main);
}
.multiselect > .prInputInput {
  display: flex;
  justify-content: space-between;
}
.multiselectOptions {
  position: absolute;
  top: 105%;
  left: 0;
  width: 100%;
  list-style-type: none;
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;
  border: 1px solid var(--light-border-10);
  border-radius: 2px;
  background-color: var(--Card-BG);
  box-sizing: border-box;
  overflow: hidden;
  z-index: 100;
}
.multiselectOptions.top {
  max-height: 200px;
  top: unset;
  bottom: 105%;
  overflow: auto;
}
.multiselectOption {
  color: var(--Text);
  padding: 12px;
  font-size: 14px;
  cursor: pointer;
  border-bottom: 1px solid var(--light-border-10);
}
.multiselectOption:hover {
  background-color: var(--Crypto);
  color: var(--Header);
}
.multiselectChip {
  display: flex;
  gap: 4px;
}
.multiselectChipLabel {
  padding: 4px 8px;
  font-size: 14px;
  font-weight: 400;
  border-radius: 2px;
  background-color: var(--Color-Main);
  color: var(--Text);
}
.iconDiv {
  fill: var(--Text);
}
.multiselectValues {
  display: flex;
  gap: 10px;
}
.prInput{
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-bottom: 30px;
}
.prInputLabel{
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 148%;
  margin-bottom:15px;
  color: var(--Text);
  position: relative;
}
.prInputLabel.inputError{
  color: var(--Error);
}
.error{
  color: var(--Error);
  text-align: left;
  margin-top: 4px;
}
.prInputInput{
  padding: 12px;
  border-radius: 2px;
  border: 1px solid var(--light-border-10);
  background: var(--input-bg-10);
  font-family: 'Montserrat';
  width: 100%;
  color: var(--Header,);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; 
}
.prInputInput:focus,textarea:focus{
  color: var(--Text);
  border: 1px solid var(--Color-Main);
  background: var(--input-bg-10);
  box-shadow: 0px 4px 5px 0px rgba(33, 1, 38, 0.03);
}

.prInputInput:disabled,textarea:disabled {
  color: var(--Disabled-Text);
  border: 1px solid var(--light-border-10);
  background: var(--Disabled);
}


.prInputInput::placeholder, textarea::placeholder{
  color: var(--Placeholder);
}
.prInputInput.inputError{
  border: 1px solid var(--Error);
}
.prInputIcon{
  position: relative;
  /* width: min-content; */
}
.prInputIconIcon{
  position: absolute;
  top: 45px;
  right: 15px;
  cursor: pointer;
}
.gameSection .prInputIconIcon{
  fill: var(--Grayscaler-Border);
}
.gameSection .prInputLabel{
  color: var(--Header);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%; /* 16px */
}
.prInputIconIcon.concept svg{
  fill: var(--Text);
}
.prInputIcon .prInputInput{
  padding-right: 45px;
}
.prFullHeight{
	width: 100%;
  max-width: 340px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	text-align: center;
}
.prHeaderAuth{
  font-size: 44px;
  font-style: normal;
  font-weight: 700;
  line-height: 128%;
  margin-bottom: 35px;
  color: var(--Header);
  position: relative;
}
.prHeaderAuth .infoHelp{
  top: -25px;
  right: -25px;
}
.prButtonMain{
  background-color: var(--Color-Main);
  color:var(--White);
  border-radius: 4px;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 132%;
  color: var(--White);
  text-align: center;
  font-size: 18px;
  /* text-transform: capitalize; */
  padding: 12px 52px;
}
.prButton.prButtonMainP{
  padding: 11px 52px;
}
.supportMessage{
  border-radius:7px;
  padding: 1px;
  width: 100%;
  position: relative;
}
.supportMessage.left{
  border: 1px solid var(--Color-Main);
}
.supportMessage.right{
  border: 1px solid var(--Informing-Approval);
}
.supportMessageHeader{
  background: var(--Main-BG);
  padding: 19px 20px 21px  62px;
  border-radius:7px 7px 0px 0px;
  color: var(--Text);
  font-style: normal;
  font-weight: 400;
  line-height: 148%; /* 20.72px */
  justify-content: space-between;
}
.supportMessage.right .supportMessageHeader{
  flex-direction:row-reverse;
}

.supportMessageHeaderRight{
  font-size: 12px;
  align-items: flex-end;
}
.supportMessageHeaderRight.openRec{
  cursor: pointer;
  transform: rotate(90deg);
  width: 12px;
  height: 12px;
}
.supportMessageHeaderRight.openRec.open{
  transform: rotate(-90deg);
}
.supportMessageHeader.create{
  padding-left: 32px;
}

.supportMessageHeaderRight svg{
  fill: var(--Text);
  width: 14px;
  height: 14px;
}
.supportMessageHeaderRight .dFlex{
  align-items: center;
}
.supportMessageHeaderLeft{
  gap: 10px;
}
.supportMessageHeaderLeft svg{
  fill: var(--Header);
}
.supportMessageHeaderLeftHead{
  color: var(--Header);
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 132%; /* 23.76px */
}
.supportMessageList{
  width: 100%;
  gap: 22px;
  display: flex;
  flex-direction: column;
}
.supportMessageBody{
  color: var(--Text);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  padding: 41px 47px 56px 32px;
  background: var(--Card-BG);
}
.supportMessageFooter{
  border-radius: 0px 0px 7px 7px;
  background: var(--Main-BG);
  padding: 19px 20px 21px 62px;
}
.supportMessageFooter .supportMessageHeaderLeftHead{
  color: var(--Header);
  margin-bottom: 13px;
}
.supportMessageFooter svg{
  fill: var(--White);
}
.supportMessageFooter .dFlex{
  color: var(--Text);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; 
  gap: 14px;
}
.supportMessageFooterList{
  gap:27px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.supportMessage.left::before{
  position: absolute;
  content: '';
  border-radius: 5px 0px 0px 5px;
  left: 0;
  top: 0;
  height: 100%;
  width: 8px;
  background: var(--Color-Main);
}
.supportMessage.right::before{
  position: absolute;
  content: '';
  border-radius: 0px 5px 5px 0px;
  right: 0;
  top: 0;
  height: 100%;
  width: 8px;
  background: var(--Informing-Approval);
}
.prButtonMain:hover{
  background-color: var(--Hover);
}
.prButtonMain:active{
  background-color: var(--Click);
}
.prButtonMain:disabled{
  background-color: var(--Disabled);
  color: var(--Disabled-Text);
}
.prButtonMainIcon{
  gap: 8px;
  display: flex;
  align-items: center;
}
.prButtonMainIcon svg{
  fill: var(--White);
}
.prButtonMainIcon.addButton svg{
  fill: var(--Color-Main);
  background-color: var(--White);
  padding: 2px;
}
.prButtonMainIcon.addButton:hover svg{
  fill: var(--Hover);
  background-color: var(--White);
  padding: 2px;
}
.prButtonMainIcon.addButton:active svg{
  fill: var(--Click);
  background-color: var(--White);
  padding: 2px;
}

.prButtonSecond{
  padding: 12px 52px;
  background-color: transparent;
  color: var(--Link);
  border: 1px solid var(--Link);
  border-radius: 4px;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 132%;
  gap: 8px;
}
.prButton.prButtonSecond svg{
  fill: var(--Link);
}
.prButtonSecond:hover{
  border: 1px solid var(--Link-Hover);
  color: var(--Link-Hover);
}
.prButtonSecond:hover svg{
  fill: var(--Link-Hover);
}
.prButtonSecond:active svg{ 
  fill: var(--Link-Click);
}
.prButtonSecond:active{
  border: 1px solid var(--Link-Click);
  color: var(--Link-Click);
}
.prButtonSecond:disabled{
  border: 1px solid var(--Disabled)!important;
  color: var(--Disabled-Text)!important;
  background: transparent!important;
}
.prButtonSecond:disabled svg{
  fill: var(--Disabled-Text);
}
.prButton.responsible {
  padding: 6px;
  font-size: 12px;
  line-height: 140%;
  background-color: transparent;
  color: var(--Header);
  border: 1px solid var(--Header);
}

.App {
  /* text-align: center; */
  font-family: 'Montserrat';
  min-height: 100%;
	display: flex;
	flex-direction: column;
}
.main{
  flex:1 1 auto;
  padding: 0 20px;
  padding-top: 180px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 1400px;
  width: 100%;
  margin: auto;
}
.adminHeaderIcons{
  display: flex;
  gap: 40px;
  background: var(--Card-BG);
  height: 100%;
  padding: 21px;
}
.adminHeaderIcons div:hover{
  cursor: pointer;
}
.adminHeaderIcons div:hover svg{
  fill: var(--Link);

}
.adminHeaderIcons svg{
  fill: var(--Text);
}
.main.auth{
  padding-top: 120px;
  justify-content: flex-start;
}
.pointer{
  cursor: pointer;
}
.createModal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -70%);
  width: 600px;
  height: 70vh;
  max-height: 580px;
  background-color: var(--Card-BG);
  z-index: 2;
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  overflow-y: auto;
  gap: 20px;
}
.createModal .prFlexRow {
  gap: 15px;
}
.halfWidth {
  width: 50%;
}
.halfWidth .prInputInput{
  width: 100%;
}
.prButtonhalfAuth{
  width: 160px;
  height: 44px;
  padding: 0;
  text-transform: none;
}
.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 1;
}
.createItem{
  padding: 0 10px;
  border-left: 4px solid black;
  border-right: 4px solid black;
}



.headerPosition{
  padding: 0 20px;
  position: fixed;
  width: 100%;
  z-index: 1;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.25);
  background: #FFF;
}
.DarkTopic .headerPosition{
  background:rgba(var(--Main-BG),0.8);
  border-bottom: 1px solid var(--Border);
  backdrop-filter: blur(25px);
}

.header{
  max-width: 1400px;
  width: 100%;
  margin: 23px auto 21px;
}
.headerAuth{
  max-width: 1400px;
  width: 100%;
  margin: 0 auto ;
  padding:0;
}
.headerTheme {
  width: 70px;
  height: 30px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 4px;
  border-radius: 20px;
  border: 1px solid var(--Border);
  margin-left: 20px;
}
.DarkTopic .headerTheme{
  border: 1px solid var(--Border);
}
.headerTheme svg{
  fill: var(--Text);
  padding: 2px;
}

.DarkTopic .headerTheme svg{
  fill: var(--Text);
}
.headerTheme .selected.active svg{
  fill: var(--White);
}
.headerTheme .selected{
  border-radius: 50%;
  width: 24px;
  height: 24px;
  padding: 0px;
}
.headerTheme .selected:hover{
  cursor: pointer;
}

.headerTheme .selected.active{
  background-color: var(--Color-Main);
}
.DarkTopic .headerTheme .selected.active{
  background-color: var(--Color-Main);
}
.openSideMenuRight .headerTheme {
  display: none;
}
.profileMainBlockInfoRight .profileFioEditName svg{
  fill: var(--Link);
  cursor: pointer;
}
.profileMainBlockInfoRight .profileFioEditName:hover svg{
  fill: var(--Link-Hover);
}
.profileMainBlockInfoRight .profileFioEditName:active svg{
  fill: var(--Link-Click);
}

.footer{
  max-width: 1400px;
  width: 100%;
  margin: 0 auto ;
}
.headerLogo{
  font-family: 'Montserrat';
  color: var(--Header);
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  display: flex;
  justify-items: center;
  align-items: center;
  gap:20px;
  
}
.headerLogoText{
  font-size: 42px;
  cursor: pointer;
}
.headerLogoText > .iconDiv {
  width: 200px;
  height: 50px;
}
.headerAuth .headerLogoText{
  font-size: 30px;
}
.prTockenEvailable{
  color: var(--Green);
}
.prTockenBlocked{
  color: var(--Yellow);
}

.profileMainBlock{
  padding: 25px 32px 0 25px;
  flex-direction: column;
  align-items: flex-start;
  height: 400px;
  width: 100%;
  position: relative;
  border-radius: 8px;
  border: 1px solid var(--Border, #CCCBCB);
  background: linear-gradient(62deg, #131313 16.8%, #1C1C1C 83.83%);
  margin-bottom: 70px;
  background-size:cover;
  background-position: center;
  background-repeat: no-repeat;
}
.DarkTopic .profileMainBlock{
  border: 1px solid var(--Border, #303030);
}
.profileMainBlock .headerUserAvatar{
  width: 170px;
  height: 170px;
  padding: 45px;
}
.profileSectionHeader{
  width: 100%;
}
.profileSectionBg{
  padding-top: 53px;
  padding-bottom: 74px;
}
.profileSectionBg::before{
  content: '';
  position: absolute;
  background: var(--Main-BG);
  width: 180%;
  height: 100%;
  z-index: -1;
  left: -50%;
  top: 0;
  opacity: 0.6;
}
.profileSectionHeaderLink.link{
  display: flex;
  flex-direction: row;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 144%;
  cursor: pointer;
  border-bottom: 2px solid transparent;
}
.profileSectionHeaderLink svg{
  fill:var(--Color-Light);
  margin-left: 37px;
  padding-top: 2px;
  vertical-align: middle;

}
.gamesPage > .prFlexRow:nth-child(2) {
  gap: 10px;
}
.gamesPage > .prFlexRow:nth-child(2) > .prInput {
  max-width: 280px;
}
.gameEdit .gameEditButtons {
  gap: 15px;
  align-items: center;
  justify-content: center;
  margin-top: 50px;
}
.gameEdit .gameEditLeft {
  flex: 0 0 49%;
}
.gameEdit .gameEditRight {
  flex: 0 0 49%;
}
.gameEditMobileBottomBlock {
  display: none;
}
.profileMainBlock .headerUserAvatar svg{
  width: 80px;
  height: 80px;
}
.filterBlock{
  margin-top: 47px;
}
.profileMainBlockInfo{
  margin-left: 33px;
}
.createUserButtonMobile {
  display: none;
}
.roomFilterMobileButton {
  display: none;
}
.createRequestMobileButton {
  display: none;
}
/* .photoUploadCover{
  position: absolute;
  left: 25px;
  top: 25px;
} */
.DarkTopic .headerMoney .headerMoneyWallet svg{
  fill: var(--Text);
}
.DarkTopic .photoUploadCover svg{
  fill: var(--Text);
  opacity: 1;

}
.photoUploadCover svg{
  fill: var(--White);
  opacity: 0.8;
}

.profileMainBlockInfoRight{
  color: var(--White);
  padding: 16px 37px 16px 30px;
  margin-left: 30px;  
  border-radius: 8px;
  border: 1px solid var(rgba(0, 0, 0, 0.20));
  background: var(--White);
  box-shadow: 0px 0px var(--Darck-shadow) 0px rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(25px);
}

.DarkTopic .profileMainBlockInfoRight{
  color: var(--White);
  border: 1px solid var(--light-border-10, rgba(217, 209, 224, 0.10));
  background: linear-gradient(248deg, rgba(31, 31, 31, 0.80) 11.63%, rgba(22, 22, 22, 0.80) 85.37%);
  box-shadow: 0px 0px  var(--Darck-shadow) 0px rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(25px);
}
.statistic{
  color: var(--Header);
  font-weight: 600;
}

.statistic .white svg{
  fill: var(--Header);
  opacity: 0.8;
}
.statistic .green svg, .active.green svg {
  fill: var(--Green);
  opacity: 0.8;
}
.statistic .red svg,.active.red svg{
  fill: var(--Fiat);
  opacity: 0.8;
}
.statistic .orange svg,.active.orange svg{
  fill: var(--Yellow);
  opacity: 0.8;
}
.profileMainBlockInfoRight svg{
  fill: var(--White);
}
.filterBlock{
  color: var(--Text);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; 
}
.filterBlockFilter{
  gap: 8px;
}
.filterBlockSort{
  margin-left: 19px;
}
.filterBlockInput{
  padding: 8px 12px;
  border-radius: 2px;
  border: 1px solid var(--light-border-10);
  background: var(--input-bg-10);
  color: var(--Header);
}
.filterBlockInput::placeholder{
  color: var(--Placeholder);
}
.filterBlockFilteredText{
  color: var(--Text);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 19.6px */
  margin-top: 6px;
}
.filterBlockSortText{
  color: var(--Text);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 148%; /* 23.68px */
}
.filterBlockSortValue{
  position: relative;
  color: var(--Color-Main);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 148%; /* 23.68px */
  gap: 7px;
  margin-left: 12px;
  cursor: pointer;
}
.filterBlockSortValue .pervert{
  transform: rotate(180deg);
}
.filterBlock svg{
  fill: var(--Color-Main);
}

.profileMainBlockInfoRightText{
  gap: 8px;
  margin-bottom: 2px;
}
.gameItem .profileMainBlockInfoRightText{
  gap: 15px;
  justify-content: flex-start;
}
.profileMainBlockInfoRightText svg{
  fill: var(--Header);
}
.gameItem.gameItemPopular .gameItemBlockInfo{
  padding: 9px 20px 9px 15px;
  border-radius: 0px 4px 4px 0px;
  border-top: 1px solid var(--light-border-10);
  border-right: 1px solid var(--light-border-10);
  border-bottom: 1px solid var(--light-border-10);
  opacity: var(--darck-100, 1);
  background: var(--White);
  background-blend-mode: overlay;
  box-shadow: 0px 0px var(--Darck-shadow) 0px rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(20px);
  bottom: 17px;
}
.DarkTopic .gameItem.gameItemPopular .gameItemBlockInfo{
  background: linear-gradient(259deg, rgba(50, 50, 50, 0.80) 18.41%, rgba(22, 22, 22, 0.80) 75.06%);
}
.gameItemBlockInfo .profileMainBlockInfoRightText svg{
  fill:var(--Text);
}
.gameItem .statisticValue{
  color: var(--Header);
  font-size: 12px;
  font-weight: 600;
  line-height: normal;
}
.gameItem .statistic svg{
  height: 13px;
}

.statisticValue{
  opacity: 0.8;
  color: var(--Header, #FFF);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%; /* 16px */
  white-space: nowrap;
}
.profileMainBlockInfoRightText .reputationNumber{
  border-radius: 50%;
  text-align: center;
  padding: 4px;
  width: 24px;
  height: 24px;
}
.profileMainBlockInfoRightNick{
  color: var(--Text);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  margin-bottom: 11px;
}
.profileFio{
  color: var(--Header);
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: 124%;
}
.profileStatus .reputationNumber{
  gap: 8px;
  border-radius: 50px;
  padding: 6px 19px 5px 17px;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: uppercase;
  margin-bottom: 14px;
  max-width: min-content;
}

.headerMoney{
  gap:23.5px;
  color: var(--Text);
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
}
.openSideMenuRight .headerMoney {
  display: none;
  padding: 10px 25px 15px;
  justify-content: flex-start;
}
.profileTabs{
  display: flex;
  overflow-y: hidden;
  overflow-x: auto;
  white-space: nowrap;
  scrollbar-width: thin;
  align-self: flex-end;
  padding: 20px 16px 0px 16px;
  align-items: center;
  gap: 16px;
  flex-shrink: 0;
  border-radius: 4px 4px 0px 0px;
  border-top: 1px solid var(--light-border-10);
  border-right: 1px solid var(--light-border-10);
  border-left: 1px solid var(--light-border-10);
  background:white;
  backdrop-filter: blur(30px);
}
.hintLink{
  color: white;
  text-decoration: underline;
  cursor: pointer;
}
.DarkTopic .profileTabs{
  border-radius: 4px 4px 0px 0px;
  border-top: 1px solid var(--light-border-10);
  border-right: 1px solid var(--light-border-10);
  border-left: 1px solid var(--light-border-10);
  background: linear-gradient(89deg, rgba(31, 31, 31, 0.60) 9.98%, rgba(22, 22, 22, 0.60) 92.79%);
  backdrop-filter: blur(30px);
  color: var(--Text); 
}
.profileTabsItems{
  padding: 0px 24px 20px;
  cursor: pointer;
  color: #2B2A35;
}
.DarkTopic .profileTabsItems{
  color: var(--Text);
}
.profileTabsItems.active{
  color: var(--Color-Light);
  position: relative;
}
.profileTabsItems.active::before{
  position: absolute;
  content: '';
  width: 100%;
  height: 3px;
  bottom: -2px;
  left: 0;
  background: var(--Color-Main);
  box-shadow: 0px 0px 10px 0px rgba(123, 97, 255, 0.30);
  border-radius: 500px;

}

.footer .headerLogo{
  color: var(--Header);
  font-size: 44.526px;
}
.headerUser{
  padding: 10px 25px 15px;
  border-left:1px solid var(--Border);
  border-right:1px solid var(--Border);
  cursor: pointer;
}
.headerUser{
  gap: 12px;
}
.openSideMenuRight .headerUser {
  display: none;
  flex-direction: column;
  align-items: flex-start;
  border: none;
}
.openSideMenuRight .headerUser .openSideMenuNavigation,
.openSideMenuRight .headerUser .openSideMenuExit {
  padding-left: 0;
  padding-right: 5px;
  width: 100%;
}
.nickname{
  color: var(--Text);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 148%; /* 23.68px */
}
.reputation{
  color: var(--Text);
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-right: 5px;
}
.reputationNumber{
  color: var(--White);
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  padding: 2px 9px 4px 8px;
  border-radius: 50px;
  background: var(--Green);
  max-width: 70px;
}
.reputationNumber.notVerified {
  background-color: var(--Error);
}
.reputationNumber.pendingVerification {
  background-color: var(--Yellow);
}
.reputationNumber.verified {
  background-color: var(--Green);
}
.reputationNumber svg{
  fill: var(--White);
}
.headerUserAvatarBlock{
  position: relative;
}
.headerUserAvatarUpload{
  position: absolute;
  left: 0;
  bottom: 0;
  width: 45.032px;
  height: 45px;
  background: var(--Card-BG);
  border-radius: 50%;
  padding: 13px;
}

.headerUserAvatarUpload svg{
  width: 20.014px;
  height: 17.778px;
  fill:var(--Header);
}
.headerUserAvatar{
  background-color: var(--Color-Main);
  background-size: cover;
  fill: var(--White);
  border-radius: 50%;
  position: relative;
  width: 40px;
  height: 40px;
  padding: 8px;
}
.headerButtons{
  gap: 21px;
}
.headerButtonsOuter {
  display: none;
}
.headerButtons svg:not(.headerMoney svg){
  cursor: pointer;
  fill:var(--Text);
}
.headerButtons svg:hover{
  fill:var(--Link-Hover);
}
.headerButtons svg:active{
  fill:var(--Link-Click);
}
.footerTop{
  padding: 76px 0 48px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}
.footerBottom{
  padding: 15px 0 42px;
}
.footerBottom div, .footerBottom a{
  opacity: 1;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
}
.footerBottom div{
  color:var(--Text);
  gap: 30px;
}

.headerLogoLogo {
  display: none;
}
.headerLogoLogo svg{
  fill: var(--Header);
}
.DarkTopic .headerLogoLogo svg{
  fill: var(--Text)
}

.profileSection{
  align-self: baseline;
  margin-bottom: 70px;
  width: 100%;
  position: relative;
  z-index: 0;
}
.profileSection h2{
  color: var(--Header);
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%; /* 36px */
  text-align: left;
  position: relative;
  width: fit-content;
}
.textWithInfoHelp{
  position: relative;
  width: fit-content;
  color: var(--Header);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%; 
  margin-bottom: 16px;
}
.infoHelp{
  position: absolute;
  fill: var(--Header);
  right: -20px;
  top: -15px;
  cursor: pointer;
}
.profileSection:not(:has(.chooseFor)) h2:before{
  content: '';
  position: absolute;
  bottom: -5px;
  left: 0;
  width: 90px;
  height: 3px;
  background-color: var(--Header);
}
.profileSection .profileSectionHeader .profileSectionHeaderH::before{
 bottom: -15px;

}

.footer .headerLogo svg{
  fill:  var(--Header);;
}
.header .prButton{
  font-size: 18px;
  line-height: 132%;
  padding: 12px 52px;
}
.createSupport{
  border-radius: 8px;
  border: 1px solid var(--Border);
  background: var(--Card-BG);
  padding: 31px 15px 35px 31px;
}
.createSupport .drop-area{
  max-width: 100%;
}
.createSupport .file-preview{
  max-width: 100%;
}
.createSupportLeft{
  width: 460px;
}
.createSupportLeftTabletBlock{
  display: none;
}
.createSupportHeader{
  color: var(--Header);
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 128%;
  margin-bottom: 24px;
}
.createSupportHeaderSup{
  color: var(--Header);
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: 124%; /* 27.28px */
  margin-bottom: 24px;
}
.createSupportTextHeader{
  color: var(--Header);
font-size: 14px;
font-style: normal;
font-weight: 700;
line-height: 148%; 
}
.supportFileDownloadItem .downloadItems svg{
  fill: var(--Text);
}
.supportMessageFooterList .iconDiv svg{
  fill: var(--Header);
}
.createSupportText{
  color: var(--Text);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; 
  margin-bottom: 12px;
}
.supportAlign{
  display: flex;
  flex-direction: column;
}
.downloadItems{
  padding: 21px 0 0px;
  gap: 10px;
}
.downloadItems svg{
  fill: var(--Header);
}
.downloadItems .createSupportTextHeader{
  color: var(--Header);
font-size: 18px;
font-style: normal;
font-weight: 300;
line-height: 132%;
}
.buttonSize button{
  width: 48%;
}
.supportAlignCenter{
  align-items: center;

}
.createSupportForm{
  margin-top: 52px;
}
.createSupportFormLabel{
  color: var(--Header);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%; 
  margin-bottom: 20px;
}
.createSelectItem{
  color: var(--Text);
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 148%; 
  margin-bottom: 5px;
  cursor: pointer;
}
.createSelectText{
  color: var(--Text, #CFCDE4);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.supportMessageBody .drop-area{
  max-width: 100%;
}

.supportMessageBody .file-preview{
  max-width: 100%;
}
.userAttempt{
  position: relative;
  padding: 11px 34px;
  position: relative;
  min-width: 200px;
  align-items: center;
  justify-content: center;
  display: flex;
}
.userAttemptList{
  position: absolute;
  top: 103%;
  left: 0;
  width: 100%;
}
.supportFileDownloadItem{
  padding-bottom: 21px;
  margin-bottom: 23px;
  border-bottom: 1px solid  var(--Separator);
}
.userAttemptList .userAttempt{
  width: 100%;
}

.DarkTopic .userAttempt{
  border: 1px solid var(--Border);
}


.userAttempt::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 1;
  background-color: var(--Color-Main);;

  z-index: -1; /* Ensure the background is behind the content */
}
.DarkTopic .userAttempt::before {
  background-color: var(--Color-Main);
  opacity: 0.6;

}

.userAttemptStatus{
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 16.8px */
  margin-left: 9px;
  margin-bottom: 2px;
  color: var(--White);
  white-space: nowrap;
  border-bottom:1px solid var(--White);
}
.authProvider svg{
  fill: var(--Link);
}
.DarkTopic .userAttemptStatus{
  color: var(--White);
}
.userAttemptTime{
  color: var(--White);
  font-size: 25px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%;
  text-align: center;
}
.addUserAttempt,.numberUserAttempt{
  width: 30px;
  height: 30px;
  border-radius: 50%;
  color:  var(--White);;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 144%;
  text-align: center;
  background: var(--Color-Light);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}
.DarkTopic .addUserAttempt,.DarkTopic .numberUserAttempt{
  border: 1px solid  var(--White);;
  background: var(--Color-Light);
}

.addUserAttempt{
  position: absolute;
  left: -15px;
  fill:  var(--White);;
  stroke:  var(--White);;
}
.numberUserAttempt{
  position: absolute;
  right: -15px;
}

.userAttempt svg{
  fill:  var(--White);;
}

.footerPositition{
  padding: 0 20px;
  border-top: 1px solid var(--light-border-10);
  margin-top: 120px;
  color: var(--Text);
}

.footerLinks{
  opacity: 1;
  /* width: 280px; */
  gap: 30px;
  justify-content: end;
}
.footerLinks svg{
  height: 30px;
  width: 30px;
}


.page404{
  font-size: 295px;
  color: var(--Text);
  font-style: normal;
  font-weight: 700;
  line-height: 128%; /* 376.746px */
  letter-spacing: 5.887px;
}
.page404Text{
  color: var(--Text);
  text-align: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 300;
  line-height: 132%; 
}
.page404Button .prButtonSecond{
  width: auto;
  padding: 12px 52px;
}
.page404Button{
  margin-top: 25px;
}
.link{
  text-decoration: none;
  color: var(--Link);
  font-size: 18px;
  font-style: normal;
  font-weight: 300;
  line-height: 132%; /* 23.76px */
  cursor: pointer;
}
.prLinkAuth{
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 148%;
  color: var(--Text);
  margin-bottom: 16px;
}
.prLinkAuth .link{
  margin-left: 18px;
  border-bottom: 1px solid var(--Link);
  font-weight: 500;
}
.prLineAuth{
  width: 284px;
  border-bottom: 1px solid  var(--Border);
  margin-bottom: 30px;
}
.link:hover{
  border-bottom: 2px solid transparent;
  border-bottom: 2px solid var(--Link-Hover);
}
.link:active{
  border-bottom: 2px solid var(--Link-Click);

}


.checkInput, .checkInput .link{
  gap:12px;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.checkInput{
  color: var(--Text);
}
.prCheckBox{
  width: 18px;
  min-width: 18px;
  height: 18px;
  position: relative;
  border-radius: 4px;
  border: 1px solid var(--Border);
  background: var(--White);
  cursor: pointer;
}

.prCheckBox.checked::before{
  position: absolute;
  width: 10px;
  height: 10px;
  content: '✔';
  color: var(--Color-Main);
  left: 1px;
  top: -2px;
  font-size: 16px;
}
.langSelect{
  background: none;
  border: none;
  color: var(--Link);
  /* margin-left: 30px; */
  /* opacity: 0.8; */
  text-decoration: none;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  cursor: pointer;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: #555;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  margin-left: -60px;
  opacity: 0;
  transition: opacity 0.3s;
}

.tooltip .tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}


.checkRoundButton{
  color: var(--Color-Light);
  background-color: var(--Color-Main);
  padding: 5px 15px;
  border-radius: 50%;
  cursor: pointer;
}
.checkRoundButton.checked{
  background-color: var(--Green);
}

.itemsList{
  gap: 10px;
}

.gameItem{
  position: relative;
  flex: 0 0 24%;
  height: 210px;
  background: black;
  border-radius: 8px;
  border: 1px solid var(--Border);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  box-shadow: 0px 0px var(--Darck-shadow) 0px rgba(0, 0, 0, 0.25), 0px 0px var(--Light-Shadow) 0px rgba(0, 0, 0, 0.05);
}
.gameItemEdit{
  position: absolute;
  right: 9px;
  top: 9px;
}
.gameItemEdit svg{
  fill:var(--White)
}
.gameItemBlockInfo{
  position: absolute;
  bottom: 14px;
  left: 0px;
  padding: 7px 14px 14px 12px;
  border-radius: 0px 4px 4px 0px;
  border-top: 1px solid var(--light-border-10);
  border-right: 1px solid var(--light-border-10);
  border-bottom: 1px solid var(--light-border-10);
  background: var(--White);
  background-blend-mode: overlay;
  box-shadow: 0px 0px  var(--Darck-shadow) 0px rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(20px);
}
.DarkTopic .gameItemBlockInfo{
  background: linear-gradient(248deg, rgba(31, 31, 31, 0.80) 11.63%, rgba(22, 22, 22, 0.80) 85.37%);
}
.gameItemBlockInfo .statistic{
  width: 194px;
}
.gameList{
  display: flex;
  margin-top: 32px;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 12px;
}

.sideMenu{
  position: relative;
}
.sideMenu .iconDiv{
  width: 25px;
  cursor: pointer;
}
.sideMenu .iconDiv:hover svg{
  fill: var(--Link-Hover);
}
.sideMenu .iconDiv:active svg{
  fill: var(--Link-Click);
}
.openSideMenu{
  position: absolute;
  right: -40px;
  top: 80px;
  width: 330px;
  border-radius: 4px;
  background: var(--white);
  border: 1px solid var(--Border);
  background: var(--Main-BG);
}
.sideMenuMobile {
  padding: 10px 0;
}
.roundButtonForIcon{
  width: 24px;
  border-radius: 50%;
  cursor: pointer;
  text-align: center;
}
.roundButtonForIcon:hover svg{
  fill: var(--Link-Hover);
}
.roundButtonForIcon:active svg{
  fill: var(--Link-Click);
}

.openSideMenu::before{
  position: absolute;
  width: 23px;
  height: 23px;
  content: ' ';
  background-color: red;
  right: 45%;
  rotate: 45deg;
  top: -13px;
  border-top-left-radius: 4px;
  border-top:1px solid var(--Border);
  border-left:1px solid var(--Border);
  background: var(--Main-BG);
}
.openSideMenu.openSideMenuRight{
  top: 59.5px;
  max-height: 300px;
  overflow: auto;
}
.openSideMenu.openSideMenuRight::before{
  right: 9%;
}

.openSideMenuNotifications{
  padding: 17px 31px 18px 28px;
  border-bottom: 1px solid var(--Border)

}
.openSideMenu.openSideMenuBig{
  width: 430px;
}
.read{
  width: 8px;
  min-width: 8px;
  height: 8px;
  min-height: 8px;
  border-radius: 50%;
  background-color: var(--Text);
  margin-top: 8px;
  margin-right: 8px;
}
.read.unread{
  background-color: var(--Color-Main);
}
.notificationItem{
  padding-bottom: 16px;
  padding-top: 16px;
  border-bottom: 1px dashed var(--Separator);
  cursor: pointer;
}
.openSideMenuNotificationsBody{
  max-height: 380px;
  overflow-y: auto;
}
.prInputLength{
  color: var(--Text);
  text-align: right;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  width: 100%; 
  padding-top: 8px;
}
.notificationItemHeader{
  color: var(--Header);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 148%;
}
.notificationItemHeader span{
  color: var(--Color-Main);
}
.notificationItemText{
  color: var(--Text);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 148%;
  word-break: break-all;
}
.notificationItemTime{
  align-items: center;
  color: var(--Text);
  font-size: 12px;
  font-style: italic;
  font-weight: 300;
  line-height: normal;
  margin-top: 4px;
  gap: 5px;
}
.notificationItem .notificationItemTime svg{
  cursor: default;
}
.notificationItem .notificationItemTime svg:hover{
  fill: var(--Text);
}
.openSideMenuNotifications h3{
  color: var(--Header, #FFF);
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: 124%; 
  padding-bottom: 20px;
  border-bottom: 1px solid var(--Separator);
}

.openSideMenuNavigation{
  padding: 17px 31px 18px 28px;
  color: var(--Text);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%; 
  border-bottom: 1px solid var(--Border)
}
.openSideMenuNavigation div{
  padding-bottom: 18px;
  padding-top: 18px;
  border-bottom: 1px dashed var(--Border);
  width: 100%;
  cursor: pointer;
}
.openSideMenuNavigation div:hover{
  color: var(--Link-Hover);
}
.openSideMenuNavigation div:active{
  color: var(--Link-Click);
}

.openSideMenuNavigation div:first-child:not(.headerTheme){
  padding-top: 0px;
}
.openSideMenuNavigation div:last-child:not(.headerTheme){
  border-bottom: none;
  padding-bottom: 0px;
}
.openSideMenuExit{
  padding: 17px 17px 17px 25px;
  color:var(--Header);
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 132%;
  display: flex;
  align-items: center;
  gap:5px;
  cursor: pointer;
}
.openSideMenuExit svg{
  fill: var(--Link);
}

.openSideMenuExit:hover{
  color:var(--Link-Hover);
}

.openSideMenuExit:active{
  color:var(--Link-Click);
}

.openSideMenuExit:hover svg{
  fill: var(--Link-Hover);
}
.openSideMenuExit:active svg{
  fill: var(--Link-Click);
}
.userItem{
  padding: 36px 0 36px 43px;
  border-radius: 8px;
  border: 1px solid var(--Border);
  position: relative;
  /* opacity: 0.5; */
  background: var(--Card-BG);
  box-shadow: 0px 0px var(--Darck-shadow) 0px rgba(0, 0, 0, 0.25);
  max-width: 100%;
  display: flex;
  align-items: center;
  gap: 18px;
  margin-bottom: 16px;
}
.userList .userItem {
  padding: 36px 40px 36px 40px;
  margin-bottom: 0;
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 0;
}
.userItem .userItemCaptain{
  position: absolute;
  top: -8px;
  right: 25px;
  color:var(--Error);
  cursor: pointer;
  border-radius: 50%;
  border: 1px solid var(--Error);
  width: 16px;
  height: 16px;  
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 2px;
}
.userItem .userItemCaptain.userItemCaptainkick{
  right: 0px;

}
.userItem.left .userItemCaptain {
  left: 25px;
}
.userItem.left .userItemCaptain.userItemCaptainkick {
  left: 0px;
}
.userItem .userItemCaptain:hover{
  opacity: 0.8;
}
.userList{
  margin-top: 25px;
}
.userItem .headerUserAvatar{
  width: 58px;
  height: 58px;
  padding: 15px;
}
.userItem .headerUserAvatar svg{
  width: 29px;
  height: 29px;
}
.userItem .statistic{
  gap: 11px;
}
.userItem .statistic .statisticValue{
  font-size: 12px;
}
.userItemNick{
  color: var(--Header);
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%;
  margin-bottom: 7px;
  display: flex;
  align-items: center;
}
.userItemNick:hover{
  color: var(--Link-Hover);
  cursor:pointer;
}
.userItemNick:active{
  color: var(--Link-Click);
}
.userItemNick svg{
  fill: var(--Link);
  margin-left: 9px;
  cursor: pointer;
}
.userItemNick svg:hover{
  fill: var(--Link-Hover);
}
.userItemNick svg:active{
  fill: var(--Link-Click);
}
.userList{
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
}
.userItem .statistic svg {
  height: 13px;
}

.headerUserAvatarReputation{
  position: absolute;
  right: -6px;
  bottom: -2px;
  width: 24px;
  height: 24px;
  background: var(--Green);
  border:2px solid var(--Card-BG);
  border-radius: 50%;
  text-align: center;
  color: var(--White);
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  padding-top: 4px;
}
.headerUserAvatarReputation.caution, .reputationNumber.caution{
  background: var(--Yellow);

}

.headerUserAvatarReputation.error, .reputationNumber.error{
  background: var(--Error);
}

.userEditLeft{
  padding: 37px 121px 72px 57px;
  position: relative;
  border-radius: 8px;
  border: 1px solid var(--Border);
  background: var(--Card-BG);
  box-shadow: 0px 0px var(--Darck-shadow) 0px rgba(0, 0, 0, 0.25);
  margin-bottom: 80px;
}

.userEditBlock > .userEditRight{
  padding: 30px 30px 70px;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  border: 1px solid var(--Border);
  background: var(--Card-BG);
  box-shadow: 0px 0px var(--Darck-shadow) 0px rgba(0, 0, 0, 0.25);
  height: min-content;  
  max-width: 32%;
  width: 100%;
}
.userEditBlock{
  display: flex;
  flex-direction: row;
  gap: 20px;
}
.userEditLeft h2,.userEditRight h2{
  color: var(--Header);
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: 124%; /* 27.28px */
  margin-bottom: 46px;
  position: relative;
  width: fit-content;
  white-space: nowrap;
}
.userEditLeft .prFlexRow{
  align-items: baseline;
  gap: 122px;
}

.userEditLeft .prInputInput,.userEditLeft .filterBlockInput{
  max-width: 320px;
  padding: 12px;
  border-radius: 2px;
  border: 1px solid var(--light-border-10);
  background: var(--input-bg-10);
}

.userEditRight .prInputInput{
  width: 100%;
}

.userEditRight .prButton{
  text-transform: unset;
}

.userEditLeft .prInputLabel{
  color: var(--Header);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%; /* 16px */
}
.userEditBlock > .userEditLeft {
  max-width: 67%;
  width: 100%;
}
.userEditBlock .date-picker,
.userEditBlock .date-picker > input {
  max-width: 100%;
  width: 100%;
}
.userEditButtons{
  display: flex;
  flex-direction: row;
  gap: 14px;
}
.buttonsGap{
  display: flex;
  align-items: center;
  gap: 16px;
  margin-top: 66px;
}
.buttonsGap .prButton{
  padding: 12px 52px;
}
.createFormInputs{
  gap: 20px;
  margin-top: 20px;
  margin-bottom: 20px;
}
.forSelect{
  margin-left: 30px;
  position: relative;
}
.forSelect::before{
  content: '';
  position: absolute;
  left: -30px;
  top: 2px;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  border: 1px solid var(--Separator);
  background-color: var(--White);
}
.forSelect.active::after{
  content: '';
  position: absolute;
  left: -24px;
  top: 8px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: var(--Color-Main);

}
.createFormInputs .prInputInput{
  max-width: 260px;
}
.overlay {
  padding-top: 68px;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: flex-end;
  z-index: 1000;
}

.sidebar {
  max-width: 406px;
  width: 100%;
  height: 100%;
  background: var(--Card-BG);
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.5);
  z-index: 1001;
}
.sidebar.bigSidebar{
  width: 100%;
  max-width: 504px;
}
.sidebar.bigSidebar .userEditButtons{
  align-self: center;
  margin-bottom: 60px;
  justify-content: center;
}
.sidebar h3{
  color: var(--Header);
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 132%; /* 23.76px */
  padding-bottom: 16px;
  padding-top: 18px;
}
.sidebar h2{
  position: relative;
  margin-top: 32px;
  padding-left: 28px;
  margin-bottom: 10px;
}
.simpleVerification{
  color: var(--Text);
  font-size: 18px;
  font-style: normal;
  font-weight: 300;
  line-height: 132%;
  margin-bottom: 20px;
}
.verification h5{
  color: var(--Text);
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 132%; 
}
.verification h3{
  color:var(--Header);
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  margin-bottom: 20px;
  margin-top: 32px;
}
.verBlock{
  justify-content: space-between;
}
.leftVer{
  width: 55%;
}
.rightVer{
  width: 40%;
  border-radius: 8px;
  border: 1px solid var(--Border);
  background: var(--Card-BG);
  box-shadow: 0px 0px var(--Darck-shadow) 0px rgba(0, 0, 0, 0.25); 
  padding: 31px;
}
.verBlockItem{
  border-radius: 8px;
  border: 1px dashed var(--Separator);
  padding: 31px 42px 0px 31px;
  margin-bottom: 20px;
}
.verBlockItem input{
  width: 100%;
}
.leftVer > .verBlockItem > div > .prFlexRow {
  gap: 20px;
}
.verBlockItem h4{
  color: var(--Header);
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: 124%;
  margin-bottom: 24px;
}
.verFile .drop-area{
  max-width: 100%;
}
.verBlockItemRadio{
  flex-wrap: wrap;
}
.verBlockItem .date-picker {
  width: 100%;
}
.verBlockItem .filterBlockInput {
  padding: 12px 12px;
} 
.radioInput{
  display: flex;
  width: 48%;
  align-items: center;
  gap: 10px;
  color: var(--Header);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 148%; 
  margin-bottom: 20px;
  cursor: pointer;
}
.adminPanel .prButtonMain{
  width: 100%;
  margin-bottom: 10px;

}
.adminPanel .prButtonMain:last-child{
  margin-bottom: 20px;
}
.verBlockItem .verBlockItemRadio input{
  width: 20px;
}

.simpleText{
  color: var(--Text);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding: 0px 18px 0px 31px;
}
.simpleTextPersonal{
  color: var(--Text);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 148%;
}
.simpleTextPersonal.mb{
  margin-bottom: 25px;
}
.verImageBlock{
  margin-bottom: 40px;
}
.verImageBlockText{
  color: var(--Header);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%; 
  margin-bottom: 18px;
}
.verImageBlock img{
  width: 100%;
}
.simpleHeaderPersonal{
  color: var(--Header);
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 148%; /* 20.72px */
  margin-bottom: 3px;
}
.sidebarFilter{
  padding-left: 18px;
  display: flex;
  gap: 16px;
  align-items: center;
}
.sidebarFilter svg{
  fill: var(--Header);
}
.sidebarFilterButtons{
  gap: 24px;
  padding-right: 16px;
  align-items: center;
}
.sidebarFilterButtons svg{
  fill: var(--Text);
  cursor: pointer;
}
.sidebarFilterButtons svg:hover{
  fill: var(--Link-Hover);
}
.sidebarFilterButtons svg:active{
  fill: var(--Link-Click);
}
.sidebarBody{
  padding: 30px 34px 100px 34px;
  overflow-y: auto;
  height: 100%;
}
.sidebarBody .userEditButtons{
  margin-bottom: 10px;
}
.sidebarBody .userEditButtons .prButton{
  padding: 8px 28px;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 148%; /* 23.68px */
  width: 100%;
}
.sidebarBody .w100{
  width: 100%;
  align-items: center;
  display: flex;
  justify-content: center;
}
.sidebarBody .prInputInput {
  width: 100%;
}
.sidebarBody .prInputLabel{
  color: var(--Header, #FFF);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%; /* 16px */
}
.sidebarBody > .prInputPeriod > .prFlexRow {
  gap: 10px;
}

.prInputPeriod .prInputLabel{
  display: block;
}
.prInputPeriod{
  margin-bottom: 30px;
}
.prInputPeriodDevider{
  color: var(--Header);
}
.prInputPeriod .filterBlockInput{
  width: 100%;
  padding: 12px;
  text-transform: lowercase;
}
.prInputPeriod .dateIcon {
  top: 10px;
}
.directoryGroup{
  border-bottom: 1px solid var(--Separator);
  width: 100%;
  color: var(--Header, #FFF);
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 128%; /* 20.48px */
}
.directoryGroupName{
  cursor: pointer;
  padding: 20px 0;
  gap: 12px;
}
.arrowForwardOpen svg{
  fill: var(--Link);
}
.arrowForwardOpen.arrowForwardOpenTransform{
  transform: rotate(180deg);
  margin-top: -4px;
}
.directoryGroupName:hover .arrowForwardOpen svg{
  fill: var(--Link-Hover);
}
.directoryGroupName:active .arrowForwardOpen svg{
  fill: var(--Link-Click);
}
.directoryGroup .prButton.prButtonMain{
  padding: 8px 28px;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 148%;
  text-transform: none;
}
.directoryElememt{
  padding: 20px 0 20px 16px;
  border-bottom: 1px dashed var(--Separator);
}
.directoryElementText{
  color: var(--Header, #FFF);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 148%;
  padding-right: 24px;
  width: 100%;
}
.directoryElementButton{
  gap: 40px;
  padding: 12px 20px 12px 20px;
  border-left: 1px solid var(--Separator);
}

.directoryElementButton svg{
  fill: var(--Link);
  cursor: pointer;
}
.directoryElementButton svg:hover{
  fill: var(--Link-Hover);
}
.directoryElementButton svg:active{
  fill: var(--Link-Click);
}
.directoryElementText .prInputInput{
  width: 100%;
  padding: 11px;
}

.prIconButton{
  cursor: pointer;
  width: 24px;
  height:24px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
}

.errorBlock{
  position: fixed;
  bottom: 30px;
  left: 30px;
  background-color: var(--Error);
  color: var(--White);
  width: 400px;
  padding: 20px;
  border-radius: 4px;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; 
}
.errorBlock::before{
  content: '';
  left: 0;
  height: 100%;
  width: 8px;
  background-color: var(--White);
  opacity: 0.3;
  position: absolute;
}
.success.errorBlock{
  background-color: var(--Green);
}

.errorBlockIcon svg{
  fill: var(--White);
  cursor: pointer;
}



.warningModal{
  position: fixed;
  bottom: 30px;
  left: 30px;
  background-color: var(--Main-BG);
  border: 1px solid var(--Border);
  border-radius: 4px;
  width: 368px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 20px 12px 20px 20px;
}
.warningModalHeader{
  color: var(--Yellow);
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 128%; /* 20.48px */
  margin-bottom: 4px;
}
.warningModalMessage{
  color: var(--Text);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  margin-bottom: 12px;
}
.warningModalButtons button{
  color: var(--Text);
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%; /* 19.6px */
  text-transform: uppercase;
  background: transparent;
  cursor: pointer;
}
.warningModalIcon{
  position: relative;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: baseline;
  margin-right: 20px;

}
.warningModalIcon::before{
  position: absolute;
  width: 40px;
  height: 40px;
  background-color: var(--Yellow);
  opacity: 0.2;
  border-radius: 50%;
  content: '';
}
.warningModalIcon svg{
  fill: var(--Yellow);
  opacity: 1;
}
.warningModal::before{
  content: '';
  height: 100%;
  width: 8px;
  background: var(--Yellow);
  border-radius: 3px 0px 0px 3px;
  opacity: 0.2;
  position: absolute;
  left: 0;
}
.warningModalIconExit{
  position: absolute;
  right: 12px;
  top: 12px; 
  cursor: pointer;
}
.warningModalIconExit svg{
  fill: var(--Separator);
}
.roomItem{
  padding-left: 18px;
  padding-right: 48px;
  border-radius: 8px;
  border: 1px solid var(--Border);
  background: var(--Card-BG);
  color: var(--Header);
  margin-bottom: 10px;
}
.roomItem.logItem {
  padding: 10px 18px;
}
.roomItemLink{
  color: var(--Link);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 19.6px */
  border-bottom: 1px solid var(--Link);
  max-width: 100px;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.roomItemLink2{
  color: var(--Link);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 19.6px */
  max-width: 300px;
}
.roomItemLink2 .roomItemBlockHeader{
  border-bottom: 1px solid var(--Link);
}
.roomItemTabletBlockHeader {
  display: none;
}
.roomItemSupport{
  padding: 10px 30px 10px 40px;
}
.roomItemSupport > .roomItemBlock {
  padding-right: 5px;
  width: 100%;
  /* overflow: hidden; */
}
.roomItemSupport > .roomItemLink2 {
  padding-right: 10px;
}
.roomItemIcon{
  width: 60px;
  justify-content: center;
}
.roomItemIcon svg{
  fill: var(--Header);
  padding: 11px 0px;
}
.roomItemTime{
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%; /* 24px */
  width: 100px;
}
.roomItemTabletTimeBlock {
  display: none;
}
.userTransactionsBlock > div {
  width: 100%; 
}
.userTransactionsBlock > .roomItemMoney.roomItemFullBlock {
  max-width: 120px;
}
.roomItemMobileBlock {
  padding-left: 10px;
}
.roomItemBlock{
  padding-left: 10px;
  border-left: 1px dashed var(--Separator);
  min-width: 100px;
}
.requestItem.verificationRequests > .roomItemBlock:first-of-type {
  padding-left: 0;
  border-left: none;
}
.roomItemBlockHeader{
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
}
.roomItemMobileBlockHeader {
  display: none;
}
.roomItemMobileTimeBlock {
  display: none;
}
.platformIcon > .roomItemBlockHeader {
  display: none;
}
.roomItemBlockValue{
  color: var(--Text);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 16.8px */
  display: flex;
  flex-direction: row;
  align-items: center;
}
.roomItemBlockValue svg{
  width: 14px;
  height: 14px;
  fill: var(--Text);
}
.roomItemMoney{
  padding: 11px 36px;
  background: rgba(115, 103, 240, 0.20);
  width: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}
.roomItemResultTablet {
  display: none;
}
.roomItemResult.win{
  background: rgba(0, 185, 152 ,0.2);
  color: var(--Green);
}
.roomItemResult.win .roomItemMoneyValue{
  color: var(--Green);
  gap: 9px;
}
.roomItemResult.win svg{
  fill: var(--Green);
}
.roomItemResult.loose{
  background: rgba(213, 26, 82 ,0.2);
  color: var(--Error);
}
.roomItemResult.loose .roomItemMoneyValue{
  color: var(--Error);
  gap: 9px;
}
.roomItemResult.loose svg{
  fill: var(--Error);
}
.roomItemResult.win, .roomItemResult.loose {
  padding: 11px 30px;
  width: 178px;
}
.roomItemMoneyValue{
  color: var(--Header);
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
}
.roomItemMoneyValue svg{
  width: 21px;
  height: 21px;
}
.roomItemMoneyValue.prFlexRow{
  justify-content: left;
}
.requestItem{
  padding: 10px 40px 10px 35px;
}
.requestItemCol {
  display: flex;
  flex-direction: column;
  gap: 15px;
}
.requestItemCol:not(:last-child) {
  border-right: 1px dashed var(--Separator);
  padding-right: 37px;
}
.roomList{
  margin-top: 48px;
}
.createRoomMobileButton {
  display: none;
}
.roomItemFullBlock .roomItemMoneyValue{
  color: var(--Header);
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%; 
}
.roomItemFullBlock .roomItemMoneyValue svg{
  width: 28px;
  height: 28px;
}
.infoText{
  display: flex;
  max-width: 410px;
  color: var(--Text);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 19.6px */
  align-items: center;
}
.infoText svg{
  width: 24px;
  height: 24px;
}
.profileSectionBlock{
  width: 100%;
  align-items: flex-start;
  gap: 20px;
}
.profileSectionBlock .userEditLeft{
  padding: 30px 20px 30px 30px;
  flex: 0 0 32%;
}
.profileSectionBlock .userEditLeft h2{
  margin-bottom: 23px;
  max-width: min-content;
}
.profileSettingsTabletBlock {
  display: none;
}
.userSettingsBlock .prInputInput,
.userSettingsBlock .date-picker,
.userSettingsBlock .filterBlockInput {
  max-width: 100%;
  width: 100%;
}
.userSettingsBlock > div:nth-of-type(2) > .userEditLeft:first-of-type > .userEditButtons {
  flex-direction: column; 
}
.profileSectionBlockMoneyInfo{
  margin-top: 31px;
}
.profileSectionBlockMoneyInfo h3{
  display: flex;
  align-items: center;
  color: var(--White);
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 148%; /* 26.64px */
}
.moneyInfoBlock{
  padding-bottom: 30px;
  border-bottom: 1px dashed var(--Separator);
}

.moneyInfoBlockItem{
  display: flex;
  align-items: center;
}

.token-info {
  padding-top: 17px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.token-row {
  display: flex;
  align-items: center;
  justify-content: flex-start; /* Вирівнюємо по лівому краю */
  gap: 10px;
}

.token-label {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 148%;
  color: var(--Header);
  min-width: 120px;
}

.token-value {
  font-weight: bold;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%; 
  padding-left: 13px;
  border-left: 1px solid var(--Separator);
}

.green {
  color: var(--Green); /* Зелений колір */
}
.token-icon{
  border: 1px solid;
  border-radius: 50%;
  width: 17px;
  height: 17px;
  text-align: center;
  font-size: 10px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  display: flex;
  align-items: center;
  justify-content: center;
}
.token-icon.green{
  border-color:  var(--Green);
}

.orange {
  color: var(--Yellow); /* Помаранчевий колір */
}
.orange.token-icon{
  border-color: var(--Yellow);
}

.radioSelect{
  display: flex;
  align-items: center;
  margin-bottom: 31px;
}

.radioSelectItem{
  width: 18px;
  height: 18px;
  background-color: var(--White);
  border-radius: 50%;
  position: relative;
  cursor: pointer;
}
.radioSelectItem.checked::before{
  content: '';
  position: absolute;
  background-color: var(--Color-Main);
  width: 10px;
  height: 10px;
  top: 4px;
  left: 4px;
  border-radius: 50%;
}
.iconDiv{
  display: flex;
  align-items: center;
  justify-content: center;
}
.button.iconDiv{
  cursor: pointer;
}

.smallInput .prInputInput{
  width: 70px;
  padding: 12px;
}
.smallInput{
  color: var(--Text);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 148%;
}

.noEditField{
  color: var(--Text);
  margin-top: -6px;
}
.editButton{
  position: absolute;
  right: 15px;
  top: 13px;
  cursor: pointer;
}
.editButton svg{
  fill: var(--Header);
}
.editButton:hover svg{
  fill: var(--Link-Hover);
}
.editButton:active svg{
  fill: var(--Link-Click);
}
.circleReputationSettings{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 76px;
  height: 76px;
  min-width: 76px;
  border-radius: 50%;
  background-color: var(--Green);
  color: var(--White);
  font-size: 40px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.circleReputationSettings.orange{
  background-color: var(--Yellow);
}
.circleReputationSettings.red{
  background-color: var(--Error);
}
.reputationRightText{
  color: var(--Text);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 148%; /* 20.72px */
}
.reputationH4{
  margin-top: 36px;
  width: min-content;
  margin-bottom: 27px;
  white-space: nowrap;
}
.reputationListItem{
  width: 100%;
  position: relative;
  border-radius: 4px;
  border: 1px solid var(--Border);
  background: var(--Main-BG);
  padding: 22px 20px 20px 23px;
  margin-bottom: 11px;
}
.reputationListItem::before{
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 6px;
  content: '';
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
}
.reputationListItem.green::before{
  background-color: rgba(0, 185, 152, 0.2);
}
.reputationListItem.red::before{
  background-color: rgba(213, 26, 82, 0.2);
}
.circleReputationSettingsSmall{
  min-width: 40px;
  height: 40px;
  border-radius: 50%;
  font-size: 50px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  display: flex;
  align-items: center;
  justify-content: center;
}
.green .circleReputationSettingsSmall{
  background: rgba(0, 185, 152, 0.2);
  color: var(--Green);
}
.red .circleReputationSettingsSmall{
  background: rgba(213, 26, 82, 0.2);
  color: var(--Error);
  padding-bottom: 7px;
}
.reputationListItemValue{
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 128%; /* 20.48px */
  margin-bottom: 4px;
  
}
.red .reputationListItemValue{
  color: var(--Error);
}
.reputationListItemRemark{
  color: var(--Text);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 19.6px */
}

.directoryElememtGame{
  width: 100%;
  padding: 0 0 12px;
}
.directoryElememtGame .directoryElementText{
  padding: 0 37px 0 0;
}
.file-upload {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  /* justify-content: center; */
  padding: 0px;
  width: 100%;
}

.drop-area {
  border: 2px dashed #59569D;
  border-radius: 12px;
  width: 100%;
  max-width: 500px;
  height: 250px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  background-color: transparent;
  color: var(--Header);
  text-align: center;
}

.drop-area .icon {
  font-size: 40px;
  margin-bottom: 10px;
  fill: var(--Color-Main);
}

.upload-btn {
  display: inline-block;
  background-color:  var(--Color-Main);
  color: white;
  padding: 10px 20px;
  border-radius: 8px;
  cursor: pointer;
  margin: 14px 0 0 0;
  transition: background-color 0.3s ease;
}
.file-text{
  color: var(--Header);
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 148%; /* 23.68px */
}
.file-text.sep{
  color: var(--Text);

}
.upload-btn:hover {
  background-color: var(--Link);;
}

.file-info {
  margin-top: 15px;
  color: var(--Text);
}

.file-preview {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 500px;
  margin-top: 10px;
  padding: 10px;
  /* border: 1px solid #59569D; */
  border-radius: 8px;
  background-color: transparent;
  color: #ffffff;
}

.file-thumbnail {
  width: 50px;
  height: 50px;
  object-fit: cover;
  border-radius: 4px;
}

.file-details {
  flex-grow: 1;
  margin-left: 10px;
}

.file-name {
  font-size: 14px;
  margin: 0;
  color: #7e64ff;
}

.file-progress-bar {
  width: 100%;
  height: 4px;
  background-color: #333;
  margin-top: 15px;
  border-radius: 2px;
  overflow: hidden;
}

.file-progress-bar .progress {
  /* width: 100%; Тут можна додати динамічну зміну ширини під час завантаження */
  height: 100%;
  background-color: #7e64ff;
}

.remove-file-btn {
  background: none;
  border: none;
  color: #ff6666;
  font-size: 20px;
  cursor: pointer;
  margin-left: 10px;
}
.remove-file-btn svg{
  fill: var(--Link);
}
.visibleTextBlock .ck.ck-content.ck-rounded-corners{
  border: 1px solid var(--Border);
  border-radius: 8px ;
  color: var(--Text);
  padding: 30px 42px 54px;
}
.visibleTextBlock .ck.ck-toolbar.ck-rounded-corners{
  border-radius: 8px 8px 0 0;
  background: #D9D9D9;
}

.switch {
  position: relative;
  display: inline-block;
  width: 70px;
  height: 34px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--White);
  transition: 0.4s;
  border-radius: 34px;
}

.slider:before {
  position: absolute;
  content: '';
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: var(--Spacer_light);
  transition: 0.4s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: var(--Color-Main);
}

input:checked + .slider:before {
  transform: translateX(36px);
}

.switchText {
  margin-left: 10px;
  font-size: 16px;
  position: absolute;
  color: var(--White);
  cursor: pointer;
}

.switchText.right{
  right: 10px;
  top: 6px;
  color: var(--Black);
}
.switchText.left{
  left: 0;
  top: 6px;
}
.progress-success{
  color: var(--Green);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 19.6px */
}
.noElem.directoryElememt{
  color: var(--Header);
}
.myGameEdit{
  display: flex;
  flex-direction: column;
  width: 450px;
}
.sidebar .chooseFor{
  color: var(--Header, #FFF);
  text-align: center;
  font-size: 30px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%; /* 36px */
}
.chooseForGame{
  text-align: center;
  font-weight: 700;
}
.platform{
  margin-left: 84px;
  margin-bottom: 28px;
  max-width: 260px;
}
.myGameEdit .userEditButtons{
  justify-content: center;
}
.myGameEdit .prInputLabel{
  color: var(--Header);
}
.gameInfo{
  color: var(--Header);
  font-size: 44px;
  font-style: normal;
  font-weight: 500;
  line-height: 128%;
  align-self: flex-end;
  border-radius: 4px 4px 0px 0px;
  border-top: 1px solid var(--light-border-10);
  border-right: 1px solid var(--light-border-10);
  border-left: 1px solid var(--light-border-10);
  opacity: var(--darck-100);
  background: linear-gradient(89deg, rgba(31, 31, 31, 0.60) 9.98%, rgba(22, 22, 22, 0.60) 92.79%);
  backdrop-filter: blur(30px);
  padding: 10px;
}
.gameInfoIcon{
  width: 74px;
  height: 68px;
  background-position: center;
  background-size: cover;
  margin-right: 23px;
}
.gameInfoPlatforms{
  margin-left: 88px;
  gap: 20px;
  margin-right: 30px;
}
.gameItemPlatformsMobile {
  display: none;
}
.gameInfoPlatforms svg{
  fill: var(--White);
}
.dangerouslySetInnerHTML{
  color: var(--Text);
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px; /* 150% */
}
.dangerouslySetInnerHTML strong{
  font-weight: 900;
}
.dangerouslySetInnerHTML i{
  font-style: italic;
}
.dangerouslySetInnerHTML p{
  margin-bottom: 28px;
}
.gameListInfo{
  color: var(--Header, #FFF);
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 148%;
  width: 70%;
}
.gameListInfo strong{
  font-weight: 700;
}
.gameListSeparator{
  height: 73px;
  border-left:1px solid var(--Separator) ;
}
/* //////////////////////////////////////////////////// */
/* //////////////////////////////////////////////////// */
/* //////////////////////////////////////////////////// */
/* //////////////////////////////////////////////////// */
/* //////////////////////////////////////////////////// */
/* //////////////////////////////////////////////////// */
/* //////////////////////////////////////////////////// */
/* //////////////////////////////////////////////////// */
/* //////////////////////////////////////////////////// */
/* //////////////////////////////////////////////////// */
/* //////////////////////////////////////////////////// */
/* //////////////////////////////////////////////////// */
/* //////////////////////////////////////////////////// */
/* //////////////////////////////////////////////////// */
/* //////////////////////////////////////////////////// */
/* //////////////////////////////////////////////////// */
.game-room {
  padding-top: 30px;
  color: var(--Header);
  max-width: 100%;
  width: 100%;
}

.room-info h1 {
  color: var(--Header);
  font-size: 38px;
  font-style: normal;
  font-weight: 300;
  line-height: 128%; 
  position: relative;
  width: fit-content;
}
.room-info h1 span{
  font-weight: 700;
}
.roomTitleMobileBlock{
 display: none;
}
.roomType{
  color: var(--Header);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; 
  background: var(--input-bg-10);
  padding: 4px 10px;
}

.room-info p {
  color: var(--Text);
}
.roomSeparator{
  height: 31px;
  border-left:1px dashed var(--Separator) ;
}
.room-info svg{
  fill: var(--Text);
}
.room-actions{
  width: 460px;
}
.room-actions-tablet {
  margin-top: 70px;
  display: none;
}
.room-actions-tablet > .prFlexRow > button {
  flex: 0 0 49%;
}
.room-actions button {
  border: none;
  padding: 10px;
  cursor: pointer;
}

.rules-btn.prButtonSecond {
  color:  var(--Link);
  border: 1px solid  var(--Link);
  margin-bottom: 10px;
  white-space: nowrap;
  padding: 10px 20px;
  text-align: center;
}

.leave-btn.prButtonSecond {
  color: var(--Error);
  border: 1px solid var(--Error);
  margin-bottom: 10px;
  white-space: nowrap;
  padding: 10px 20px;
  text-align: center;
  border-radius: 8px;
}

.admin-btn {
  color: var(--White);
  width: 100%;
  margin-bottom: 10px;
}

.main-body {
  display: flex;
  /* justify-content: space-between; */
  margin-top: 45px;
  width: 100%;
  gap: 20px;
}

.team {
  width: 33%;
  background-color: transparent;
  padding: 15px;
  /* margin-right: 15px; */
  border-right:1px solid var(--Separator);
}
.team.right{
  border-left:1px solid var(--Separator);
  border-right: none;
}

.team h2 {
  margin: 0 0 10px;
  justify-content: right;
  padding-bottom: 26px;
  border-bottom: 1px solid var(--Separator);
  display: flex;
  gap: 10px;
  color: var(--Header, #FFF);
  font-size: 24px;
  font-weight: 700;
  line-height: 120%; 
}
.team.right h2{
  justify-content: left;

}
.team-member {
  display: flex;
  flex-direction: column;
  justify-content: right;
  align-items:end;
  gap: 20px;
  padding: 10px 0;
  border-bottom: 1px solid #444;
}

.team-member:last-child {
  border-bottom: none;
}
.right .team-member{
  align-items:baseline;

}

.team-member .userItem{
  background: transparent;
  border: none;
  padding: 0px 14px 13px 0px;
  border-bottom: 1px dashed var(--Separator);
  margin-bottom: 0;
  border-radius: 0;
  box-shadow: unset;
  width: 265px;
}
.team-member .userItem .statistic{
  gap: 8px;
}
.team-member .userItem.left{
  justify-content: end;
}
.team-member .left .userItemNick{
  justify-content: end;
}
.team-member .headerUserAvatar.ready::before{
  position: absolute;
  content: '';
  border: 4px solid var(--Green);
  top: 0px;
  left: 0px;
  width: 50px;
  height: 50px;
  border-radius: 50%;
}
.team-member .headerUserAvatar.notready::before{
  position: absolute;
  content: '';
  border: 4px solid var(--Error);
  top: 0px;
  left: 0px;  
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

.team-member .headerUserAvatar .captain{
  position: absolute;
  top: -2px;
  right: -6px;
  width: 24px;
  height: 24px;
  border: 2px solid black;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: var(--Yellow);
  color: var(--White);
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.timerWait{
  color: var(--Header);
  position: relative;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%;  
  margin: 0 auto;
  max-width: fit-content;
}
.timerTime{
  color: var(--Header);
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
}

.stats span {
  margin-right: 5px;
}

.join-btn {
  margin-top: 15px;
  background-color: #5a5a8b;
  padding: 10px;
  border: none;
  color: white;
  width: 100%;
  cursor: pointer;
  border-radius: 5px;
}
.room-status-block-tablet{
  display: none;
}
.middle-section {
  text-align: center;
  padding: 10px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.middle-sectioHeader{
  color: var(--Color-Main);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px; /* 214.286% */
  position: relative;
  max-width: fit-content;
  margin: 0 auto;
}
.middle-sectioPrice{
  color: var(--Header);
  font-size: 25px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  display: flex; 
  align-items: center;
  justify-content: center;
  margin-bottom: 40px;
}
.middle-sectioPrice svg{
  fill: transparent;
}
.roomInfoLine{
  align-items: center;
  gap: 10px;
}
.roomDetailsMobile {
  display: none;
}
.timer {
  font-size: 24px;
  font-weight: bold;
  color: #f0ad4e;
}

.chat-section {
  padding: 15px;
  border-radius: 5px;
}
.chatSectionHeader{
  color: var(--Header);
  font-size: 30px;
  font-style: normal;
  position: relative;
  max-width: fit-content;
  font-weight: 700;
  line-height: 120%; /* 36px */
  margin-bottom: 5px;
}
.chatSectionSeparator{
  width: 90px;
  border-bottom: 3px solid var(--Header);
  margin-bottom: 22px;
}

.chat-header {
  display: flex;
  /* border-bottom: 1px solid #444; */
  background-color: var(--light-border-10);
}

.chat-tab {
  cursor: pointer;
  color: var(--Header);
  background-color: transparent;
  padding: 5px 0px;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px;
  width: 150px;
  text-align: center;
}
.chat-tab.alert{
  position: relative;
}
.chat-tab.alert::before{
  position: absolute;
  content: '';
  right: 10px;
  width: 10px;
  height: 10px;
  background-color: var(--Error);
  border-radius: 50%;
}
.chat-tab.active {
  color: var(--Color-Main);
  background: var(--Card-BG);
  border-top: 1px solid var(--Color-Main);
}

.chat-body {
  overflow-y: auto;
  background-color:  var(--Card-BG);
  height: 400px;
}

.chatBodySeparator{
  border-bottom: 1px solid var(--light-border-10);
  padding-top: 18px;
  padding-top: 28px;
  background-color: var(--Card-BG);
}
.chatMessageAvatar{
  min-width: 40px;
  max-height: 40px;
  background-color: var(--Crypto);
  background-size: cover;
  border-radius: 50%;
  margin-right: 11px;
  margin-left: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.chatMessageAvatar svg{
  fill: white;
}
.chatFooterButton{
  min-width: 165px;
  max-width: 165px;
}
.chatMessageText{
  margin-bottom: 20px;
}
.chatMessageTextNick{
  color: var(--Header);
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%; 
  margin-bottom: 7px;
}
.chatMessageTextText{
  color: var(--Text);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  width: 270px;
} 
.chat-message.my{
  justify-content: end;
}
.chat-message.my .chatMessageTextNick{
  text-align: end;
}
.chat-message.my .chatMessageTextText{
  text-align: right;
}
.chat-message.my .chatMessageAvatar{
  margin-left: 10px;
  margin-right: 27px;
}

.chat-footer {
  padding-right: 5px;
  display: flex;
  justify-content: end;
  overflow-y: auto;
  background-color:  var(--Card-BG);
  padding-bottom: 20px;
}
.chat-footer.top{
  padding-top:20px ;
  border-top: 1px solid var(--light-border-10);
}

.chat-footer input {
  width: 80%;
  padding: 10px;
}

.room-description {
  margin-top: 52px;
  padding: 15px;
}
.room-description-tablet {
  display: none;
}
.roomDescriptionTabs{
  border-radius: 4px 4px 0px 0px;
  background: var(--input-bg-10);
  width: min-content;
  padding: 0 16px;
}
.roomDescriptionText{
  padding: 41px 5px 29px 16px;
  width: 100%;
  border-top: 1px solid var(--Separator);
}
.roomDescriptionHeader{
  white-space: nowrap;
  color: var(--Text);
  text-align: center;
  text-shadow: 0px 0px var(--Darck-shadow) rgba(123, 97, 255, 0.30);
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 132%; /* 23.76px */
  padding: 20px 24px 20px;
  cursor: pointer;
}
.roomDescriptionHeader.active{
  color: var(--Color-Light);
  border-bottom: 2px solid var(--Color-Light);
}
.beUser{
  cursor: pointer;
  border-radius: 8px;
  background: var(--Separator);
  color: var(--Header);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; 
  width: 100%;
  justify-content: end;
}
.beUserPlus{
  display: flex;
  min-width: 58px;
  min-height: 58px;
  max-width: 58px;
  max-height: 58px;
  border-radius: 50%;
  border: 1px dashed var(--Header);
  font-size: 36px;
  justify-content: center;
  align-items: center;
  margin: 7px 14px;
}
.beUserText{
  align-self: center;
}
.beUserText:not(.beUser.right > .beUserText) {
  text-align: end;
}
.beUser.right {
  justify-content: left;
  padding-right: 5px;
}
.beUser:not(.beUser.right) {
  padding-left: 5px;
}
.beUser.right .beUserPlus{
  margin-left: 9px;
  margin-right: 19px;
}
.timeSmallText{
  color: var(--Text);
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  margin-top: 18px;
  margin-bottom: 8px;
}
.leavingRoomText{
  color: var(--Header);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 19.6px */
}
.leavingRoomText h3{
  color: var(--Header);
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%; 
}
.leavingRoomText div{
  margin-bottom: 20px;
}
.attentionPart{
  border-radius: 4px;
  border: 1px solid var(--Border);
  background: var(--Main-BG);
  padding: 22px 21px 29px 19px;
  position: relative;
}
.attentionPart::before{
  position: absolute;
  content: '';
  height: 100%;
  left: 0;
  top: 0;
  width: 6px;
  background-color: var(--Yellow);
  opacity: 0.2;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
}
.attentionPart h3{
  color: var(--Yellow);
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 128%; 
  margin-top: 0;
  padding-top: 0;
}
.attentionPart div{
  color: var(--Text);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; 
  margin-bottom: 20px;
}
.attentionPart div:last-child{
  margin-bottom: 0px;
}
.warningModalIcon {
margin-left: 10px;
}
.attentionIcon svg{
  fill: var(--Yellow);
}
.resultBlock{
  border-radius: 8px;
  border: 1px solid var(--Border);
  background: var(--Card-BG);
  padding: 17px 15px 27px;
  margin-top: 32px;
}
.headerResult{
  color: var(--Header, #FFF);
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 12px;
  text-align: left;
}
.resultTeam{
  align-items: center;
}
.teamSeparator{
  border-bottom: 1px dashed var(--Separator);
  margin-top: 11px;
  margin-bottom: 7px;
}
.resultTeamName{
  color: var(--Header);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  margin-right: 15px;
  margin-left: 5px;
}
.resultBlockResult{
  border-radius: 4px;
  border: 1px solid var(--Border);
  background: var(--Main-BG);
}
.resultIcon {
  padding: 10px 12px 9px;
  cursor: pointer;
}
.resultIcon svg{
  fill: var(--Text);
}
.resultIcon:nth-child(2){
  border-left: 1px solid var(--Border);
  border-right: 1px solid var(--Border);
}
.headerResultNick {
  color: var(--Text);
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}
.headerResultNick span{
  color: var(--Header, #FFF);
  font-weight: 700;
}
.resultTeam.ready{
  align-items: center;
  justify-content: center;
}
.resultTeam.ready .resultTeamName{
  margin-right: 0;
}
.resultTeamName.red{
  color: var(--Error);
}
.resultTeamName.green{
  color: var(--Green);
}
.resultTeamName.orage{
  color: var(--Yellow);
}
.refCommentText{
  color: var(--Text);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 16.8px */
  text-align: left;
}

.attentionBlockInfo{
  color: var(--Yellow);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; 
  padding: 16px 37px 16px 18px;
  text-align: left;
  border-radius: 4px;
  border: 1px solid var(--Border);
  background: var(--Main-BG);
  align-items: center;
  gap: 12px;
  position: relative;
}
.attentionBlockInfo::before{
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 4px;
  height: 100%;
  border-radius: 3px 0px 0px 3px;
  opacity: 0.2;
  background: var(--Yellow);
}
.attentionBlockInfo svg{
  fill: var(--Yellow);
}
.infoWithPrev{
  color: var(--Text);
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: 148%;
  padding: 4px 0 4px 16px;
  position: relative;
}
.infoWithPrev::before{
  position: absolute;
  content: '';
  left: 0;
  width: 4px;
  height: 100%;
  top: 0;
  background: var(--Color-Main);
}
.roomDescriptionText h3{
  color: var(--Header);
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: 124%; 
  margin-bottom: 27px;
}
.roomDescriptionText .userItem{
  background: transparent;
  border: none;
  box-shadow: none;
}
.information{
  margin-left: 61px;
  color: var(--Text);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; 
}
.information p{
  color: var(--Text);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; 
  margin-bottom: 23px;
  text-align: left;
}
.infoHead{
  color: var(--Header);
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%;
  white-space: nowrap;
  margin-bottom: 16px;
}
.infoSeparator{
  border-left: 1px dashed var(--Separator);
}
.informationBlock{
  gap: 33px;
}
.infoFull{
  border-bottom: 1px dashed var(--Separator);
  padding-bottom: 22px;
  margin-bottom: 29px;
}
.infoFullPos{
  align-self: start;
}
.infoFullPos .userItem{
  padding: 0;
  justify-content: end;
}
.infoFullPos .userItem .userItemNick{
  justify-content: end;
}
.errorMessageResult{
  color:  #FF80A6;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 148%; 
  margin-top: 33px;
}
.informationResult{
  margin-bottom: 20px;
}
.informationResult .resultTeam{
  justify-content: normal;
}

.userItemFilter{
  color: var(--Text);
  display: flex;
  gap:20px;
  align-items: center;
  cursor: pointer;
  padding: 5px;
  border: 1px solid var(--Text);
  border-radius: 5px;
  position: relative;
  margin-bottom: 10px;
}
.userItemFilter.selected::before{
  background-color: var(--Green);
  opacity: 0.2;
  width: 100%;
  height: 100%;
  position: absolute;
  content: '';
  left: 0;
  top: 0;
}

.userItemFilter svg{
  fill: var(--Text);
}
.userItemFilterAvatar{
  background-color: var(--Color-Main);
  background-size: cover;
  border-radius: 50%;
  padding: 10px;
}

.croppedText {
  max-width: 100px;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.emptyDiv {
  width: 1px;
  height: 1px;
  display: none;
  overflow: hidden;
}

.sideMenuMobile {
  display: none;
}

.roomItemTabletMoney {
  display: none;
}

.roomItem:not(.logItem, .allRooms, .userTransactionsBlock, .roomItemSupport) > div:not(.roomItemMoney, .roomItemTime) {
  width: 100%;
}

.dateTimeBlock {
  justify-content: flex-start;
}

.documentImage {
  width: 28px;
  height: 28px;
  border-radius: 16px;
}

.documentImage > img {
  width: 100%;
}

.pointer {
  cursor: pointer;
}

.logsPage .prInput {
  margin: 0;
}

.logsPage .prButton.prButtonMain {
  margin: 0;
  justify-content: center;
  align-self: flex-end;
  min-width: 250px;
}

.logItem .roomItemBlockHeader {
  font-size: 10px;
  font-weight: 400;
  color: var(--Text);
}

.logItem .roomItemBlockValue {
  font-size: 14px;
  font-weight: 400;
  color: var(--Header);
}

.logItem .roomItemBlock {
  max-width: 100%;
  width: unset;
  display: flex;
  flex-direction: column;
  gap: 7px;
}

.logItem > div:last-of-type {
  flex-direction: row;
}

.logItem > div:last-of-type .iconDiv {
  fill: var(--White);
}

.tabsContainer {
  margin: 0;
  list-style-type: none;
  padding: 16px 16px 16px 0;
  min-width: 250px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  background-color: var(--Card-BG);
  border-radius: 8px;
}

.tabItem {
  position: relative;
  padding: 12px 23px;
  font-size: 18px;
  font-weight: 400;
  color: var(--Text);
  cursor: pointer;
}

.tabItem:hover, .tabItem.active {
  font-weight: 600;
  color: var(--Color-Light);
}

.tabItem:hover::before, .tabItem.active::before {
  content: '';
  height: 100%;
  width: 3px;
  position: absolute;
  top: 0;
  left: 0;
  background-color: var(--Color-Light);
  border-radius: 500px;
}

.systemSettings {
  display: flex;
  gap: 20px;
}

.systemSettings > div:first-of-type {
  max-width: 300px;
  width: 100%;
}

.systemSettings > div:not(:first-of-type){
  width: 100%;
}

.conditionsPage {
  margin-top: -60px;
  padding-bottom: 40px;
}

.conditionsPage h2 {
  position: relative;
  font-size: 44px;
  font-weight: 500;
  line-height: 128%;
  color: var(--Header);
}

.conditionsPage > .attentionPart {
  padding: 20px;
  gap: 20px;
}

.conditionsPage > .attentionPart > .warningModalIcon {
  margin: 0;
}

.conditionsImagesWrapper {
  max-width: 100%;
  gap: 10px;
}

.conditionsImage {
  max-width: 270px;
  overflow: hidden;
  max-height: fit-content;
}

.conditionsImage > img {
  max-width: 100%;
  border-radius: 4px;
}

.faq {
  margin-top: 64px;
  padding: 30px;
  width: 100%;
  border: 1px dashed var(--Separator);
  border-radius: 5px;
}

.conditionsPage .directoryGroup {
  flex-wrap: wrap;
}

.conditionsPage .answer {
  width: 100%;
}

.hintWrapper {
  position: relative;
}

.hintContainer {
  position: absolute;
  left: -8px;
  width: 330px;
  z-index: 1;
}

.hintContainer.alignRight {
  position: absolute;
  left: unset;
  right: -12px;
}
.hintContainer.alignCenter {
  position: absolute;
  left: unset;
  transform: translateX(-165px);
}

.hintContainerArrow {
  position: relative;
  top: 11px;
  left: 6px;
  width: 20px;
  height: 20px;
  clip-path: polygon(0% 0%, 100% 0%, 0% 100%);
  transform: rotate(45deg);
  background-color: var(--Card-BG);
  border: 1px solid var(--Border);
  border-radius: 4px;
  z-index: 2;
}

.hintContainerArrow.alignRight {
  position: relative;
  left: unset;
  right: -300px;
}
.hintContainerArrow.alignCenter {
  position: relative;
  left: unset;
  right: -50%;
}

.hintContainerBody {
  display: flex;
  flex-direction: column;
  background-color: var(--Card-BG);
  border: 1px solid var(--Border);
  border-radius: 4px;
  box-shadow: var(--HintSHadow);
}

.hintHeading {
  padding: 15px 17px;
  font-size: 16px;
  line-height: 128%;
  color: var(--Header);
  border-bottom: 1px solid var(--Border);
  text-align: left;
  /* word-break: break-all; */
  text-wrap: wrap;
}

.hintBody {
  padding: 15px 17px;
  font-size: 14px;
  line-height: 140%;
  font-weight: 400;
  color: var(--Text);
  word-break: break-all;
  text-wrap: wrap;
}

.hintsElement {
  align-items: flex-start;
  gap: 20px;
}

.hintsElement .directoryElementText {
  padding: 0;
}

.baseInput {
  color: var(--Text);
  background-color: transparent;
}

.flex {
  display: flex;
}

.flexCol {
  display: flex;
  flex-direction: column;
}

.alignCenter {
  align-items: center;
}

.box-shadow {
  box-shadow: 0px 0px 20px 0px rgba(123, 97, 255, 0.30);
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}

* {
  scrollbar-width: thin;
}

.switch {
  position: relative;
  display: inline-block;
  width: 70px;
  height: 32px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 3px;
  background-color: var(--White);
  -webkit-transition: .4s;
  transition: .4s;
  z-index: 10;
}

.switchCheckbox:checked + .slider {
  background-color: var(--Color-Main);
}

.switchCheckbox:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
}

.switchCheckbox:checked + .slider:before {
  -webkit-transform: translateX(36px);
  -ms-transform: translateX(36px);
  transform: translateX(36px);
}

.switchLabel {
  position: absolute;
  bottom: 7px;
  right: 10px;
  font-size: 14px;
  font-weight: 400;
  color: var(--Border);
  -webkit-transition: .1s;
  transition: .1s;
}

.switchLabel.checked {
  visibility: hidden;
  position: absolute;
  bottom: 7px;
  right: unset;
  left: 10px;
  font-size: 14px;
  font-weight: 400;
  color: var(--White);
  -webkit-transition: .1s;
  transition: .1s;
}

.switchCheckbox:checked + .slider + .switchLabel {
  visibility: hidden;
}

.switchCheckbox:checked + .slider + .switchLabel + .switchLabel.checked {
  visibility: visible;
}

.relative {
  position: relative;
}

@media (max-width: 1410px) and (min-width: 1024px) {
  .game-room > .dFlex {
    flex-direction: column;
  }

  .room-info > .main-body {
    justify-content: space-between;
  }

  .room-actions-tablet {
    width: 50%;
    display: block;
  }

  .room-actions > .prFlexRow:first-of-type,
  .room-actions > button {
    display: none;
  }

  .room-description {
    display: none;
  }

  .room-actions {
    margin-top: 50px;
    width: 100%;
    display: flex;
    gap: 30px;
  }

  .room-actions > .chat-section,
  .room-actions > .room-description-tablet {
    margin: 0 !important;
    padding: 0;
    width: 100%;
  }

  .room-description-tablet {
    display: block;
  }
}

@media (max-width: 1390px) {
  .userList {
    grid-template-columns: repeat(3, 1fr);
  }

  .userList .userItem {
    padding: 25px;
  }
}

@media (max-width: 1250px) {
  .sideMenuMobile {
    display: block;
  }

  .headerAuth .headerTheme {
    display: none;
  }

  .openSideMenuRight .headerTheme {
    display: flex;
  }
}

@media (max-width: 1155px) {
  .headerAuth .headerLogoText {
    display: none;
  }

  .headerAuth .headerLogoLogo {
    display: block;
  }

  .gameList .gameItem {
    flex: 0 0 32%;
  }
}

@media (max-width: 1024px) {
  .headerLogoText:not(.footer .headerLogoText) { 
    display: none;
  }

  .header .headerLogoLogo {
    display: block;
  }

  .headerAuth .headerMoney {
    display: none;
  }

  .openSideMenuRight .headerMoney {
    display: flex;
  }

  .headerAuth .headerButtons > div:nth-child(1) {
    display: none;
  }

  .walletBalanceBlock {
    flex-wrap: wrap;
    gap: 0;
    row-gap: 20px;
  }

  .walletBalanceBlock > div:first-child {
    margin-bottom: 0;
    flex: 0 0 100%
  }

  .walletBalanceBlock > div:not(:first-child) {
    flex: 0 0 49%
  }

  .profileSettingsTabletBlock > .prFlexRow > .userEditLeft:first-of-type > .userEditButtons {
    flex-direction: column; 
  }

  .userSettingsBlock .prInputInput,
  .userSettingsBlock .date-picker,
  .userSettingsBlock .filterBlockInput {
    max-width: 100%;
    width: 100%;
  }

  .userSettingsBlock button:not(.adminPanel .prButtonMain) {
    width: 100%;
    margin: 0 !important;
  }

  .game-room > .dFlex {
    flex-direction: column;
  }

  .main-body {
    gap: 10px;
  }

  .team-member .userItem {
    width: 100%;
  }

  .prFlexRow.statistic {
    flex-wrap: wrap;
  }

  .userList .prFlexRow.statistic {
    flex-wrap: nowrap;
  }

  .room-actions-tablet {
    margin-top: 70px;
    display: block;
    width: 100%;
  }

  .room-actions-tablet > .prFlexRow > button {
    flex: 0 0 49%;
  }

  .room-actions {
    width: 100%;
  }

  .room-actions > .prFlexRow:first-of-type,
  .room-actions > button {
    display: none;
  }

  .team {
    padding: 5px;
  }

  .room-description {
    display: none;
  }

  .room-description-tablet {
    margin-top: 70px;
    display: block;
  }

  .chat-section {
    padding: 0;
  }

  .gameItemCard > .prFlexRow:first-child {
    flex-direction: column;
    gap: 14px;
  }

  .gameItemCard > .prFlexRow:first-child > div {
    align-self: flex-start;
  }

  .gameList .gameItem {
    flex: 0 0 49%;
  }

  .gameEdit .gameEditButtons {
    flex-direction: column;
    align-items: stretch;
  }

  .gameEditLeft > div:first-child {
    flex-direction: column;
    gap: 30px !important;
    margin-bottom: 30px;
  }

  .gameEditLeft > div:first-child > div > .prInput:last-child {
    margin-bottom: 0;
  }

  .gameEditLeft > .prInput {
    display: none;
  }

  .gameEditMobileBottomBlock {
    display: block;
  }

  .usersPage .filterBlockSort {
    margin-left: 0;
    order: -1;
  }

  .usersPage .filterBlock > .prFlexRow > .prFlexRow {
    flex-wrap: wrap;
    gap: 7px;
  }

  .supportListPage .roomFilterButton {
    padding: 14px !important;
    height: 100%;
    font-size: 0;
    gap: 0;
  }

  .createSupportPage > .dFlex {
    flex-direction: column;
  }

  .createSupportLeft {
    width: 100%;
  }

  .supportAlign > .buttonsGap {
    justify-content: center;
  }

  .requestItem {
    flex-direction: column;
    gap: 0 !important;
  }

  .requestItemCol {
    max-width: 100% !important;
    width: 100%;
    gap: 0;
  }

  .requestItemCol:not(:last-child) {
    border: none;
    padding: 0;
  }

  .requestItemCol > div {
    padding: 10px 0;
    display: flex;
    justify-content: space-between; 
    border-bottom: 1px dashed var(--Separator);
  }

  .requestItemCol:last-child > div {
    border: none;
  }

  .verificationRequests .requestItemCol:last-of-type > div:not(:last-of-type) {
    border-bottom: 1px dashed var(--Separator);
  }

  .requestItem .roomItemBlock {
    padding: 10px 0;
    width: 100%;
    display: flex;
    justify-content: space-between;
    border: none;
    border-bottom: 1px dashed var(--Separator);
  }

  .requestItem .roomItemBlock:last-of-type {
    border: none;
  }

  .requestItem > .roomItemBlock > .roomItemLink {
    display: block !important;
  }

  .createSupportLeftTabletBlock {
    display: flex;
    gap: 16px;
  }

  .supportItemDetails > .dFlex {
    flex-direction: column;
  }

  .supportItemDetails > .dFlex > .supportAlign > div:nth-of-type(2),
  .supportItemDetails > .dFlex > .supportAlign > div:nth-last-child(1) {
    display: none;
  }

  .supportItemDetails > .dFlex > .supportAlign > .buttonSize > button {
    width: 100%;
  }

  .userEditBlock {
    flex-direction: column;
    align-items: center;
    margin-bottom: 70px;
  }

  .userEditBlock > .userEditLeft {
    padding: 30px;
    margin-bottom: 0;
    max-width: 100%;
    width: 100%;
  }

  .userEditBlock > .userEditRight {
    max-width: 472px;
    width: 100%;
  }

  .logItem {
    flex-wrap: wrap !important;
  }

  .logItem > div:nth-of-type(2),
  .logItem > div:nth-of-type(3) {
    display: none;
  }

  .logItem > div:nth-of-type(4) {
    padding-left: 0px;
  }

  .logItem > div:last-of-type {
    border-right: none;
  }

  .logItem > .roomItemTabletBlockHeader {
    display: flex;
    width: 100%;
    justify-content: space-between;
  }

  .systemSettingsInputs {
    flex-direction: column;
  }

  .systemSettingsInputs > div:last-of-type {
    width: 100%;
  }

  .systemSettingsInputs .file-upload {
    align-items: center;
  }

  .userList {
    grid-template-columns: repeat(2, 1fr);
  }

  .roomItemSupport > .roomItemBlock,
  .roomItemSupport .roomItemLink2 {
    padding: 10px 0;
    width: 100%;
    max-width: 100%;
    display: flex;
    justify-content: space-between;
    border: none;
    border-bottom: 1px dashed var(--Separator);
  }

  .roomItemSupport > .roomItemBlock:last-of-type {
    border-bottom: none;
  }
}

@media (max-width: 1024px) and (min-width: 768px) {
  .roomItem {
    flex-wrap: wrap;
    padding: 12px 18px;
    justify-content: stretch;
    row-gap: 12px;
  }

  .roomItemTabletBlockHeader {
    display: flex;
    width: 100%;
    justify-content: space-between;
  }

  .roomItemTabletBlockHeader > div:last-child {
    gap: 15px;
  }

  .roomItemTabletBlockHeader > div:last-child > div:nth-of-type(2) {
    margin-left: 0px !important;
  }

  .roomItemLink:not(.roomItemTabletBlockHeader > .roomItemLink, .visible) {
    display: none;
  }

  .roomItemMoney {
    display: none;
  }

  .roomItemIcon:not(.roomItemTabletTimeBlock > .roomItemIcon),
  .roomItemTime:not(.roomItemTabletTimeBlock > .roomItemTime) {
    display: none;
  }

  .platformIcon {
    display: flex !important;
  }
  
  .roomItemTabletMoney {
    display: block;
  }

  .roomItemBlock {
    padding: 0 20px;
    flex-grow: 1;
  }

  .roomItemBlock, .roomItemMobileBlock {
    padding: 0 20px;
    border: none;
    border-right: 1px dashed var(--Separator);
  }

  .actualRoomsBlock .roomItemBlock:nth-of-type(7) {
    padding: 0;
  }

  .historyRoomsBlock .roomItemBlock:nth-of-type(4) {
    padding: 0;
  }

  .historyRoomsBlock .roomItemBlock:nth-of-type(8) {
    border-right: none;
  }

  .actualRoomsBlock .roomItemBlock:nth-last-child(1),
  .actualRoomsBlock .roomItemBlock:nth-last-child(2),
  .historyRoomsBlock .roomItemBlock:nth-last-child(1),
  .historyRoomsBlock .roomItemBlock:nth-last-child(2) {
    display: none;
  }

  .actualRoomsBlock .roomItemBlock:nth-child(4) {
    padding-left: 0;
  }

  .userTransactionsBlock .roomItemMoney {
    display: block;
    order: 1;
    font-size: 16px;
  }

  .userTransactionsBlock .roomItemMoneyValue {
    font-size: 16px;
    font-weight: 500;
  }

  .userTransactionsBlock .roomItemBlock:nth-last-child(1) {
    display: none;
  }

  .userTransactionsBlock .roomItemBlock:nth-last-child(2) {
    border: none;
  }

  .userTransactionsBlock .roomItemBlock:nth-child(4) {
    padding-left: 0;
  }

  .userTransactionsBlock > div:nth-child(3) {
    display: none;
  }

  .userTransactionsBlock > div:not(.roomItemTabletBlockHeader) {
    width: unset;
  }

  .roomItemResult:not(.roomItemResultTablet) {
    display: none;
  }

  .roomItemResultTablet {
    display: block;
  }

  .walletBalanceBlock > div:first-child > .prflexColumn {
    flex-direction: row;
    column-gap: 60px;
  }

  .moneyInfoBlock {
    border: none;
  }

  .profileSectionBlock:has(.profileSettingsTabletBlock) > *:not(.profileSettingsTabletBlock) {
    display: none;
  }

  .profileSettingsTabletBlock {
    display: flex;
    flex-direction: column;
    row-gap: 16px;
  }

  .profileSettingsTabletBlock > .prFlexRow {
    align-items: stretch;
    column-gap: 16px;
  }
  
  .profileSettingsTabletBlock > .prFlexRow > .userEditLeft {
    flex: 0 0 49%;
    margin-bottom: 0 !important;
  }

  .roomItem > div:not(.roomItemMoney, .roomItemMoney, .roomItemTabletBlockHeader, .requestItemCol) {
    width: unset;
  }

  .roomItem.roomItemSupport > div {
    width: 100%;
  }

  .roomItem > .roomItemTabletTimeBlock {
    display: flex;
    align-items: center;
    width: 100px !important;
  }

  .roomItem.verificationRequests > div {
    width: 100%;
  }
}

@media (max-width: 890px) {
  .roomItemSupport {
    flex-direction: column;
    gap: 0;
  }

  .verBlock {
    margin-top: 20px;
    flex-direction: column-reverse;
  }

  .verBlock > div {
    width: 100%;
  }
}

@media (max-width: 768px) {
  .emptyDiv {
    display: block;
  }

  .headerAuth > .prFlexRow {
    width: 100%;
  }

  .headerAuth .headerUser {
    display: none;
  }

  .openSideMenuRight .headerUser {
    display: flex;
  }

  .openSideMenu.openSideMenuRight {
    max-height: unset;
    height: calc(100vh - 76px);
    overflow: auto;
  }
  .openSideMenu.openSideMenuRight.downloadCli{
    height:fit-content
  }

  .prButton.prButtonMain.register {
    display: none;
  }

  .main.auth {
    padding: 150px 10px 0px;
  }

  .profileMainBlock {
    padding: 16px 0 0 10px;
    margin-left: -10px;
    margin-right: -10px;
    width: calc(100% + 20px);
    height: unset;
  }

  .DarkTopic .profileMainBlock {
    border: none;
    border-radius: unset;
    border-bottom: 1px solid var(--Border, #303030);;
  }

  .profileMainBlockInfo {
    margin-left: 0;
    margin-top: 80px;
    margin-bottom: 60px;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px
  }

  .headerUserAvatarBlock {
    margin-left: 13px;
  }

  .profileMainBlockInfoRight {
    margin: 0;
  }

  .gameItem {
    width: 100%;
    height: 264px;
  }

  .roomItem {
    padding: 10px 18px 22px;
    flex-direction: column;
  }

  .roomItemBlock {
    padding: 10px 0;
    width: 100%;
    display: flex;
    justify-content: space-between;
    border: none;
    border-bottom: 1px dashed var(--Separator)
  }

  .roomItemMobileBlock {
    padding: 10px 0;
    width: 100%;
    display: flex;
    justify-content: space-between;
    border: none;
    border-bottom: 1px dashed var(--Separator)
  }

  .roomItemBlock:last-of-type {
    border: none;
  }

  .roomItemMobileBlockHeader {
    width: unset !important;
    margin-left: -18px;
    margin-right: -18px;
    padding: 0 18px;
    padding-bottom: 14px;
    align-self: stretch;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px dashed var(--Separator);
  }

  .platformIcon > .roomItemBlockHeader {
    display: block;
    flex-grow: 1;
  }

  .logItem > .roomItemBlock {
    width: 100%;
  }

  .roomItemLink:not(.roomItemMobileBlockHeader .roomItemLink, .logItem > .roomItemTabletBlockHeader > .roomItemLink, .visible) {
    display: none;
  }

  .roomItemMobileBlockHeader > .roomItemMoney {
    padding: 10px 22px;
    width: auto;
  }

  .roomItemMoney:not(.roomItemMobileBlockHeader > .roomItemMoney) {
    display: none;
  }

  .room-info > .roomTitle {
    display: none;
  }

  .room-info > .roomTitleMobileBlock {
    display: flex;
  }

  .room-info > .roomTitleMobileBlock > .roomItemMoney {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .roomTitleMobileBlock {
    margin-bottom: 20px;
  }

  .roomTitleMobileBlock svg {
    fill: none;
  }

  .roomTitleMobileBlock > * {
    width: 100%;
  }

  .roomTitleMobileBlock > .prflexColumn {
    justify-content: space-between;
  }

  .roomTitleMobileBlock > .prflexColumn > .flex > h1 {
    display: flex;
    flex-direction: column;
    font-size: 14px;
  }

  .roomTitleMobileBlock > .prflexColumn > h1 > span {
    display: flex;
    flex-direction: column;
    font-size: 24px;
  }

  .roomItemMobileTimeBlock {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 7px;
    border-bottom: 1px dashed var(--Separator);
  }

  .roomItemMobileTimeBlock > .roomItemIcon {
    width: auto;
    height: auto;
  }

  .roomInfoLine {
    display: none;
  }

  .roomDetailsMobile {
    display: flex;
  }

  .roomDetailsMobile > * {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: none;
    border-right: 1px dashed var(--Separator);
    min-width: unset;
  }

  .roomDetailsMobile > span {
    border-right: none;
  }

  .roomItemIcon:not(.roomItemMobileTimeBlock > .roomItemIcon),
  .roomItemTime:not(.roomItemMobileTimeBlock > .roomItemTime) {
    display: none;
  }

  .platformIcon {
    display: flex !important;
  }

  .platformIcon svg {
    padding: 0;
  }

  .roomFilterButton {
    padding: 14px !important;
    height: 100%;
    font-size: 0;
    gap: 0;
  }

  .historyRoomsBlock > .prFlexRow:has(.roomItemResult) {
    padding: 10px 0;
    width: 100%;
    justify-content: center;
    border-bottom: 1px dashed var(--Separator);
  }

  .userTransactionsBlock > div:nth-child(3) {
    display: none;
  }

  .profileSectionHeader:has(.createRoomMobileButton) {
    flex-wrap: wrap;
    row-gap: 18px;
  }

  .prFlexRow.profileSectionHeader:has(.createRoomMobileButton) > .prFlexRow > button:first-of-type {
    display: none;
  }

  .createRoomMobileButton {
    display: block;
    width: 100%;
  }

  .filterBlock > .prFlexRow > .prFlexRow {
    flex-wrap: wrap;

  }

  .filterBlockFilter > div > input {
      max-width: 180px;
  }

  .filterBlockSort {
    margin-left: 0;
    order: -1;
  }

  .userSettingsBlock {
    flex-direction: column;
    align-items: stretch;
  }

  .userSettingsBlock > div:not(.profileSettingsTabletBlock) {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  .userSettingsBlock .userEditLeft {
    margin: 0 !important;
  }

  .room-status-block-tablet {
    display: block;
    margin-top: 50px;
  }

  .team, .team.right {
    width: 100%;
    border: none;
    position: relative;
  }

  .team:not(.team.right)::after {
    position: absolute;
    content: "";
    top: 0;
    right: -5px;
    width: 1px;
    height: 100%;
    background-color: var(--Separator);
  }

  .userItem {
    flex-direction: column;
  }

  .userItem .headerUserAvatar {
    order: -1;
    background-size: cover;
  }

  .userItem.left .userItemNick {
    justify-content: center;
  }

  .userItem.right .userItemNick {
    justify-content: center;
  }

  .middle-section:not(.room-status-block-tablet > .middle-section) {
    display: none;
  }

  .prFlexRow.gamePage {
    flex-direction: column;
    align-items: stretch;
    gap: 20px;
  }

  .prFlexRow.gamePage > .gameListSeparator {
    display: none;
  }

  .prFlexRow.gamePage > .gameListInfo {
    width: 100%;
  }

  .gameList .gameItem {
    flex: 0 0 100%;
  }

  .gameImagesBlock {
    flex-direction: column;
  }

  .gameImagesBlock .drop-area,
  .gameImagesBlock .file-preview {
    max-width: 100%;
  }

  .footerTop {
    flex-direction: column;
    gap: 60px;
    border-bottom: none;
  }

  .footerTop .headerLogo {
    font-weight: 300;
    font-size: 32px;
  }

  .footerBottom {
    flex-direction: column-reverse;
    gap: 60px;
  }

  .footerBottom > div:first-child {
    padding-top: 18px;
    width: 100%;
    text-align: center;
    border-top: 1px solid var(--light-border-10);
  }

  .footerBottom > div:last-child {
    flex-direction: column;
    gap: 14px;
  }

  .walletBalanceBlock {
    flex-direction: column;
    margin-bottom: 70px;
  }

  .walletBalanceBlock > .userEditLeft {
    width: 100%;
    margin: 0;
  }

  .gameInfo .gameInfoPlatforms {
    margin-left: 60px;
  }

  .gameEdit > div:nth-child(2) > div:first-child {
    flex-direction: column;
    margin-bottom: 37px;
  }

  .visibleTextBlock .ck.ck-content.ck-rounded-corners {
    padding: 30px 20px 42px;
  }

  .usersPage > .profileSectionHeader > button:not(.roomFilterMobileButton) {
    display: none;
  }

  .usersPage .roomFilterButton {
    display: none;
  }

  .usersPage .roomFilterMobileButton {
    display: block;
  }

  .createUserButtonMobile {
    margin-top: 20px;
    display: block;
    width: 100%;
  }

  .supportListPage > .profileSectionHeader > .gameSection > button:first-of-type {
    display: none;
  }

  .createRequestMobileButton {
    display: block;
    width: 100%;
  }

  .createSupportForm > .createFormInputs {
    flex-direction: column;
  }

  .createSupportForm > .createFormInputs > .prInput {
    margin-bottom: 0;
  }

  .createSupportForm > .createFormInputs > .prInput > .prInputInput {
    max-width: 100%;
  }

  .createSupportLeftTabletBlock {
    flex-direction: column;
  }

  .supportItemDetails > .dFlex > .supportAlign > .buttonSize > button {
    padding: 12px 15px;
  }

  .supportMessageList > .supportMessage > .supportMessageBody {
    padding: 15px 15px 30px 15px;
  }

  .userEditBlock > .userEditLeft > .prFlexRow {
    flex-direction: column;
  }

  .userEditBlock > .userEditLeft .prInputInput {
    max-width: 100%;
  }

  .userEditBlock > .userEditRight {
    max-width: 100%;
  }

  .listFilterSection {
    flex-wrap: wrap;
  }

  .listFilterSection > *{
    min-width: unset;
    flex: 0 0 48%;
  }

  .listFilterSection > .prButton.prButtonMain {
    min-width: unset;
    padding: 12px 30px;
  }

  .systemSettings {
    flex-direction: column;
  }

  .systemSettings > div:first-of-type {
    max-width: 100%;
    width: 100%;
  }

  .conditionsImagesWrapper {
    flex-direction: column;
  }

  .conditionsImagesWrapper > .iconDiv {
    transform: rotate(90deg);
  }

  .roomTitleMobileBlock > .roomItemMoney {
    max-width: 200px;
  }
}

@media (max-width: 720px) {
  .profileTabs {
    align-self: stretch;
  }
}

@media (max-width: 480px) {
  .headerAuth .headerButtons > div:nth-child(2):not(.sideMenu) {
    display: none;
  }

  .headerAuth .userAttempt {
    padding: 11px 7px;
  }

  .headerAuth .headerLogoLogo {
    width: 35px;
  }

  .headerAuth .headerButtons > div:last-of-type .iconDiv:not(.sideMenuMobile .iconDiv) {
    width: 6px;
  }

  .adminHeaderIcons {
    gap: 15px;
  }

  .openSideMenu.openSideMenuRight {
    width: calc(100vw + 10px);
  }

  .main {
    padding: 0 10px;
    padding-top: 180px;
  }

  .profileSection h2 {
    font-size: 22px;
    font-weight: 600;
  }

  .headerPosition {
    padding: 0 10px;
  }

  .headerTheme {
    display: none;
  }

  .prHeaderAuth {
    font-size: 22px;
    font-weight: 600;
    align-self: flex-start;
    border-bottom: 2px solid var(--Header);
  }

  .prHeaderAuth .infoHelp {
    top: -5px;
    right: -20px;
  }

  .gameItem {
    height: 210px;
  }

  .profileSectionHeaderLink.link {
    font-size: 16px;
    font-weight: 400;
    color: var(--Header);
    border-bottom: 1px solid var(--Header)
  }

  .profileSectionHeaderLink > .iconDiv {
    display: none;
  }

  .filterBlockFilter {
    flex-wrap: wrap;
  }

  .filterBlockSortText {
    width: 100%;
  }

  .filterBlockFilter > div > input {
    min-width: 100px;
    max-width: 130px;
  }

  .roomTitleMobileBlock {
    flex-direction: column;
    gap: 20px;
  }

  .roomTitleMobileBlock > .prflexColumn {
    gap: 6px;
  }

  .room-actions-tablet > .prFlexRow {
    flex-direction: column;
  }

  .room-actions-tablet > .prFlexRow > button {
    width: 100%;
  }

  .room-info > .main-body {
    flex-direction: column;
  }

  .main-body > .team > h2 {
    justify-content: center;
    padding-bottom: 20px;
  }

  .gameInfo > *:not(:first-child) {
    display: none;
  }

  .gameInfo > .gameItemPlatformsMobile {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .gameInfo .gameInfoPlatforms {
    margin-left: 0;
  }

  .footerPositition {
    padding: 0 10px;
  }

  .bigSidebar > .sidebarBody > .prInput:nth-child(2) > .prFlexRow > div:nth-child(2) {
    margin-left: 15px !important;
    width: 50px !important;
    gap: 8px;
  }

  .bigSidebar > .sidebarBody > .prInput:nth-child(2) > .prFlexRow > div:nth-child(3) {
    margin-left: 10px !important;
    width: 50px !important;
    gap: 8px;
  }

  .sidebar .userEditButtons {
    flex-direction: column;
  }

  .gamesPage > .prFlexRow:nth-child(2) {
    flex-direction: column;
    align-items: stretch;
  }

  .gamesPage > .prFlexRow:nth-child(2) > .prInput {
    margin: 0;
    max-width: 100%;
  }

  .file-details > .file-name {
    max-width: 150px;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .createSupportForm > .prFlexRow {
    flex-direction: column;
    align-items: flex-start;
    gap: 15px;
  }

  .supportAlign > .buttonsGap {
    flex-direction: column;
    align-items: stretch;
  }

  .supportMessageHeader:not(.create) {
    flex-direction: column;
    padding-left: 10px;
  }

  .supportMessageList > .supportMessage > .supportMessageBody > .buttonSize {
    flex-direction: column;
  }

  .supportMessageList > .supportMessage > .supportMessageBody > .buttonSize > button {
    width: 100%;
  }

  .supportMessage.right > .supportMessageHeader {
    flex-direction: column;
    align-items: flex-end;
  }

  .supportMessage.right > .supportMessageHeader > .supportMessageHeaderLeft .supportMessageHeaderLeftSec {
    text-align: left;
  }

  .prFlexRow.directoryGroup {
    padding-bottom: 10px;
    flex-direction: column;
    align-items: flex-start;
  }

  .prFlexRow.directoryGroup > div:last-of-type, .prFlexRow.directoryGroup > div:last-of-type > button {
    width: 100%;
    justify-content: center;
  }

  .userEditButtons.userEditPage {
    width: 100%;
    flex-direction: column;
  }

  .userEditButtons.userEditPage > button {
    width: 100%;
  }

  .listFilterSection {
    flex-direction: column;
  }

  .listFilterSection > .prButton.prButtonMain {
    width: 100%;
    margin-top: 15px;
  }

  .systemSettings .userEditButtons > button {
    padding: 12px 20px;
  }

  .userList {
    grid-template-columns: repeat(1, 1fr);
  }

  .roomTitleMobileBlock > .roomItemMoney {
    max-width: 100%;
  }
}
