.pagination-container {
  display: flex;
  list-style-type: none;
  justify-content: center;
  margin-top: 64px;
  margin-bottom: 0px;
}

.pagination-item {
  padding: 0 12px;
  height: 32px;
  text-align: center;
  margin: auto 4px;
  color: var(--Text);
  display: flex;
  box-sizing: border-box;
  align-items: center;
  letter-spacing: 0.01071em;
  border-radius: 16px;
  line-height: 1.43;
  font-size: 13px;
  min-width: 32px;
}

.pagination-item.dots:hover {
  background-color: transparent;

  cursor: default;
}

.pagination-item:hover {
  cursor: pointer;
  color: var(--Link-Hover);
}

.pagination-item.selected {
  color: var(--Color-Main);
}

.arrow svg{
  fill: var(--Link);
}

.arrow:hover svg{
  fill: var(--Link-Hover);
}
.disabled .arrow svg{
  fill: var(--Disabled);
}

.pagination-item.disabled {
  pointer-events: none;
}

.arrow::before {
  border-right: 0.12em solid var(--Color-Main);
  border-top: 0.12em solid  var(--Color-Main);
}

.pagination-item.disabled :hover {
  background-color: transparent;
  cursor: default;
}
