:root {
	--headerH1: 16px;
}

html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video, button, input, textarea {
	margin: 0;
	padding: 0;
	border: 0;
	vertical-align: baseline;
	outline: none;
	box-sizing: border-box;
	font: inherit;
}

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
	display: block;
}
html{
   height:100%;
}
body{
   height:100%;
   min-height: 100%;
}
#root{
   height: 100%;
}
@font-face {
   font-family: 'Montserrat';
   font-style: italic;
   font-weight: 200;
   src:
      url('fonts/Montserrat-ExtraLightItalic.woff') format('woff');
   }
   
   @font-face {
   font-family: 'Montserrat';
   font-style: italic;
   font-weight: 300;
   src:
      url('fonts/Montserrat-LightItalic.woff') format('woff');
   }
   
   @font-face {
   font-family: 'Montserrat';
   font-style: italic;
   font-weight: 500;
   src:
      url('fonts/Montserrat-MediumItalic.woff') format('woff');
   }
   
   @font-face {
   font-family: 'Montserrat';
   font-style: italic;
   font-weight: 600;
   src: 
      url('fonts/Montserrat-SemiBoldItalic.woff') format('woff');
   }
   
   @font-face {
   font-family: 'Montserrat';
   font-style: italic;
   font-weight: 800;
   src: 
      url('fonts/Montserrat-ExtraBoldItalic.woff') format('woff');
   }
   
   @font-face {
   font-family: 'Montserrat';
   font-style: italic;
   font-weight: 900;
   src: 
      url('fonts/Montserrat-BlackItalic.woff') format('woff');
   }
   
   @font-face {
   font-family: 'Montserrat';
   font-style: italic;
   font-weight: normal;
   src: 
      url('fonts/Montserrat-Italic.woff') format('woff');
   }
 @font-face {
   font-family: 'Montserrat';
   font-style: normal;
   font-weight: 100;
   src:
      url('fonts/Montserrat-Thin.woff') format('woff');
   }
 @font-face {
   font-family: 'Montserrat';
   font-style: normal;
   font-weight: 200;
   src: 
      url('fonts/Montserrat-ExtraLight.woff') format('woff');
   }
   
   @font-face {
   font-family: 'Montserrat';
   font-style: normal;
   font-weight: 300;
   src: 
      url('fonts/Montserrat-Light.woff') format('woff');
   }
   
   @font-face {
   font-family: 'Montserrat';
   font-style: normal;
   font-weight: 400; /* Assuming this is your regular weight */
   src: 
      url('fonts/Montserrat-Regular.woff') format('woff');
   }
   
   @font-face {
   font-family: 'Montserrat';
   font-style: normal;
   font-weight: 500;
   src:
      url('fonts/Montserrat-Medium.woff') format('woff');
   }
   
   @font-face {
   font-family: 'Montserrat';
   font-style: normal;
   font-weight: 600;
   src: 
      url('fonts/Montserrat-SemiBold.woff') format('woff');
   }
   
   @font-face {
   font-family: 'Montserrat';
   font-style: normal;
   font-weight: 700; /* Assuming this is your bold weight */
   src: 
      url('fonts/Montserrat-Bold.woff') format('woff');
   }
   
   @font-face {
   font-family: 'Montserrat';
   font-style: normal;
   font-weight: 800;
   src:
      url('fonts/Montserrat-ExtraBold.woff') format('woff');
   }
   
   @font-face {
   font-family: 'Montserrat';
   font-style: normal;
   font-weight: 900;
   src: 
      url('fonts/Montserrat-Black.woff') format('woff');
   }
   